<template>
  <div ref="root" class="toast align-items-center border-0 text-white p-2" :class="background" role="alert"
       aria-live="assertive" aria-atomic="true">
    <div class="d-flex">
      <div class="toast-body fw-bold">
        {{ message }}
      </div>
      <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
              aria-label="Close"></button>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import Toast from "bootstrap/js/dist/toast"

const root = ref()

const emit = defineEmits(['onToastExpired'])

defineProps({
  background: {
    type: String,
    default: 'bg-primary'
  },
  message: String
})

onMounted(() => {
  const toast = new Toast(root.value, {})
  root.value.addEventListener('hidden.bs.toast', () => emit('onToastExpired'))
  toast.show()
})

</script>
<style>

</style>