import {useAuthStore} from "@/stores/auth";
import axios from "axios";
import router from "@/router";

const isolatedAxios = axios.create({baseURL: process.env.VUE_APP_BACKEND_URL})
let refreshInProgress = false

export async function resetAndRedirectToLogin(origin) {
    const authStore = useAuthStore()
    authStore.user = null
    authStore.access_token = null
    authStore.refresh_token = null
    await router.push({path: '/login', query: {origin: origin}})
}

export async function handleAxiosRequest(config) {
    const authStore = useAuthStore()
    while (refreshInProgress) {
        await new Promise(r => setTimeout(r, 100))
    }

    if (authStore.access_token != null) {
        config.headers['Authorization'] = `Bearer ${authStore.access_token}`
    }

    return config
}

export async function handleAxiosResponse(error) {
    if (!error.response || ![401, 403].includes(error.response.status)) {
        return Promise.reject(error)
    }

    if (error.response.status === 403) {
        //await router.push({path: '/forbidden'})

        return Promise.reject(error)
    }

    const authStore = useAuthStore()


    if (!authStore.refresh_token) {
        await resetAndRedirectToLogin(error.request.path)

        return Promise.reject(error)
    }

    if (!refreshInProgress) {
        refreshInProgress = true
        try {
            await refreshTokens()
        } catch (e) {
            refreshInProgress = false
            await resetAndRedirectToLogin(error.request.path)

            return Promise.reject(error)
        }
    } else {
        while (refreshInProgress) {
            await new Promise(r => setTimeout(r, 100))
        }
    }

    const config = error.response.config
    config.headers['Authorization'] = `Bearer ${authStore.access_token}`

    try {
        return await isolatedAxios.request(config)
    } catch (e) {
        await resetAndRedirectToLogin(error.request.path)

        return Promise.reject(error)
    } finally {
        refreshInProgress = false
    }
}

async function refreshTokens() {
    const authStore = useAuthStore()
    const formData = new FormData()
    formData.append('refresh_token', authStore.refresh_token)
    formData.append('grant_type', 'refresh_token')

    const refreshResponse = await isolatedAxios.post('/auth/token', formData, {headers: {'Content-Type': 'multipart/form-data'}})

    authStore.access_token = refreshResponse.data.access_token
    authStore.refresh_token = refreshResponse.data.refresh_token
}