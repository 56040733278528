<template>
  <div class="user-select-none">
    <div class="d-flex align-items-center pe-3 bg-light rounded-pill d-inline-flex">
      <ProfileImage :user="user" :size="42" :disabled="disabled"/>
      <span class="fw-bold ms-2 text-nowrap" :class="disabledClass">{{ user.first_name }} {{ user.last_name }}</span>
    </div>
  </div>
</template>
<script setup>
import ProfileImage from "@/components/Common/ProfileImage";
import {computed} from "vue";

const props = defineProps({
  user: Object,
  disabled: Boolean
})

const disabledClass = computed(() => {
  if (props.disabled) {
    return 'text-secondary'
  }

  return null
})
</script>
<style>

</style>