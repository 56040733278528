<template>
  <div class="mt-4">
    <span v-if="mailType" class="fs-4 fw-bold">{{ $t(`template.mail_types.${mailType}`) }}</span>
    <div v-if="recipients">
      <span>{{ $t('template.help.recipients') }}:</span>
      <ul>
        <li v-for="recipient in recipients" :key="recipient">
          {{ $t(`template.mail_recipients.${recipient}`) }}
        </li>
      </ul>
    </div>

    <form @submit.prevent="saveTemplate">
      <ul class="nav nav-tabs" id="languageTab" role="tablist">
        <li v-for="language in languages" :key="language.locale" class="nav-item" role="presentation">
          <button class="nav-link" :class="`${language.locale === 'en' ? 'active' : ''}`" :id="`${language.locale}-tab`"
                  data-bs-toggle="tab" :data-bs-target="`#${language.locale}-tab-pane`" type="button"
                  role="tab" :aria-controls="`${language.locale}-tab-pane`" aria-selected="true">
            {{ language.name }}
          </button>
        </li>
      </ul>

      <div class="tab-content" id="languageTabContent">
        <div v-for="language in languages" :key="language.locale" class="tab-pane"
             :class="`${language.locale === 'en' ? 'show active' : ''}`" :id="`${language.locale}-tab-pane`"
             role="tabpanel" :aria-labelledby="`${language.locale}-tab`" tabindex="0">

          <div class="mb-3">
            <label :for="`${language.locale}TemplateSubject`" class="form-label">
              {{ $t("template.form.subject") }}
            </label>
            <input v-model="form.translations.subject[language.locale]" type="text" class="form-control"
                   :id="`${language.locale}TemplateSubject`"
                   :aria-describedby="`${language.locale}TemplateSubjectHelp`"
                   maxlength="200" :disabled="loading">
            <div :id="`${language.locale}TemplateSubjectHelp`" class="form-text d-flex">
              {{ $t("template.form.subject_help") }}
            </div>
          </div>

          <div class="mb-3">
            <label :for="`${language.locale}Template`" class="form-label">
              {{ $t("template.form.template") }}
            </label>
            <textarea v-model="form.translations.template[language.locale]" rows="20" class="form-control"
                      :id="`${language.locale}Template`"
                      :aria-describedby="`${language.locale}TemplateHelp`" :disabled="loading"></textarea>
            <div :id="`${language.locale}TemplateHelp`" class="form-text d-flex">
              {{ $t("template.form.template_help") }}
            </div>
          </div>

        </div>
      </div>

      <div class="form-check form-switch mt-2">
        <input v-model="form.active" class="form-check-input" type="checkbox" role="switch" id="activeSwitch"
               :disabled="loading">
        <label class="form-check-label" for="activeSwitch">{{ $t('common.active') }}</label>
      </div>

      <div class="d-flex justify-content-end mt-2 mb-2">
        <router-link to="/templates" class="btn btn-secondary me-2">{{ $t("common.cancel") }}</router-link>
        <button type="submit" class="btn btn-primary">{{ $t('common.save') }}</button>
      </div>
    </form>

  </div>
</template>
<script setup>
import {Languages} from "@/constants";
import {onMounted, ref} from "vue";
import {computed} from "vue";
import {useRoute, useRouter} from "vue-router";
import axios from "axios";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const route = useRoute()
const router = useRouter()
const loading = ref(false)
const mailType = ref(null)
const form = ref({
  translations: {
    subject: {},
    template: {}
  },
  active: false
})

onMounted(() => loadTemplate())

const languages = computed(() => {
  const languages = []
  for (const [locale, language] of Languages) {
    languages.push({locale: locale, name: language})
  }

  return languages
})

const recipients = computed(() => {
  switch (mailType.value) {
    case 'submission_submitted':
      return ['creator']
    case 'submission_submitted_broadcast':
      return ['broadcast']
    case 'submission_state_changed_user':
      return ['creator_state_change']
    case 'submission_state_changed':
      return ['assignees']
    case 'submission_assignee_added':
      return ['assignee_added', 'assignees']
    case 'submission_assignee_removed':
      return ['assignee_removed', 'assignees']
    case 'submission_reminder':
      return ['creator']
    case 'submission_expired':
      return ['creator']
    default:
      return null
  }
})

function saveTemplate() {
  loading.value = true
  axios
      .put(`/template/${route.params.id}`, form.value)
      .then(() => {
        emitter.emit('toast', {
          text: i18n.t('template.save_success')
        })
        router.push('/templates')
      })
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('template.save_error')
        })
      })
      .finally(() => loading.value = false)
}

function loadTemplate() {
  loading.value = true
  axios
      .get(`/template/${route.params.id}/translations`)
      .then(response => {
        mailType.value = response.data.mail_type
        form.value.active = response.data.active
        form.value.translations.subject = response.data.translations.subject || {}
        form.value.translations.template = response.data.translations.template || {}
      })
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}
</script>
<style>

</style>