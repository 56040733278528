<template>
  <div class="mt-4">
    <h4 class="mb-3" v-if="isEdit">{{ $t('document.type.form.edit') }}</h4>
    <h4 v-else>{{ $t('document.type.form.new') }}</h4>

    <form @submit.prevent="save">
      <ul class="nav nav-tabs" id="languageTab" role="tablist">
        <li v-for="language in languages" :key="language.locale" class="nav-item" role="presentation">
          <button class="nav-link" :class="`${language.locale === 'de' ? 'active' : ''}`" :id="`${language.locale}-tab`"
                  data-bs-toggle="tab" :data-bs-target="`#${language.locale}-tab-pane`" type="button"
                  role="tab" :aria-controls="`${language.locale}-tab-pane`" aria-selected="true">
            {{ language.name }}
          </button>
        </li>
      </ul>

      <div class="tab-content" id="languageTabContent">
        <div v-for="language in languages" :key="language.locale" class="tab-pane"
             :class="`${language.locale === 'de' ? 'show active' : ''}`" :id="`${language.locale}-tab-pane`"
             role="tabpanel" :aria-labelledby="`${language.locale}-tab`" tabindex="0">

          <div class="mb-3">
            <label :for="`${language.locale}DocumentTypeName`" class="form-label">{{ $t("common.name") }}</label>
            <input v-model="form.translations.name[language.locale]" type="text" class="form-control"
                   :id="`${language.locale}DocumentTypeName`"
                   :aria-describedby="`${language.locale}DocumentTypeNameHelp`"
                   maxlength="200" :required="['de', 'en'].includes(language.locale)">
            <div :id="`${language.locale}DocumentTypeNameHelp`" class="form-text d-flex">
              {{ $t("document.type.form.name_help") }}
            </div>
          </div>

          <div class="mb-3">
            <label :for="`${language.locale}DocumentTypeDescription`" class="form-label">
              {{ $t("common.description") }}
            </label>
            <textarea v-model="form.translations.description[language.locale]" rows="3" class="form-control"
                      :id="`${language.locale}DocumentTypeDescription`"
                      :aria-describedby="`${language.locale}DocumentTypeDescriptionHelp`"
                      maxlength="500" :required="['de', 'en'].includes(language.locale)"></textarea>
            <div :id="`${language.locale}DocumentTypeDescriptionHelp`" class="form-text d-flex">
              {{ $t("document.type.form.description_help") }}
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <router-link to="/document/types" class="btn btn-secondary me-2">{{ $t("common.cancel") }}</router-link>
        <button v-if="isEdit" @click="deleteType" type="button" class="btn btn-danger me-2">
          {{ $t("common.delete") }}
        </button>
        <button type="submit" class="btn btn-primary">
          <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{ $t("common.save") }}
        </button>
      </div>
    </form>
  </div>
</template>
<script setup>
import {computed, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {Languages} from "@/constants";
import axios from "axios";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const route = useRoute()
const router = useRouter()
const form = ref({translations: {name: new Map(), description: new Map()}})
const loading = ref(false)

const isEdit = computed(() => {
  return route.params.id
})

const languages = computed(() => {
  const languages = []
  for (const [locale, language] of Languages) {
    languages.push({locale: locale, name: language})
  }

  return languages
})

onMounted(() => {
  if (isEdit.value) loadType()
})

function save() {
  if (isEdit.value) {
    updateType()
  } else {
    newType()
  }
}

function loadType() {
  loading.value = true
  axios
      .get(`/document/type/${route.params.id}/translations`)
      .then(response => form.value.translations = response.data.translations)
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}

function newType() {
  loading.value = true
  axios
      .post('/document/type', form.value)
      .then(() => router.push('/document/types'))
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}

function updateType() {
  loading.value = true
  axios
      .put(`/document/type/${route.params.id}`, form.value)
      .then(() => router.push('/document/types'))
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}

function deleteType() {
  loading.value = true
  axios
      .delete(`/document/type/${route.params.id}`)
      .then(() => router.push('/document/types'))
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}


</script>
<style>

</style>