<template>
  <TopBar v-if="shouldDisplayTopBar"></TopBar>
  <div v-if="shouldDisplayContent" class="container">
    <router-view/>
  </div>

  <div v-if="!shouldDisplayContent && (loading || error)"
       class="d-flex justify-content-center align-items-center vh-100">
    <div v-if="loading" class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div v-if="error">
      Error
      {{ error }}
    </div>
  </div>

  <div aria-live="polite" aria-atomic="true" class="position-fixed bottom-0 end-0 p-3">
    <div class="toast-container">
      <MessageToast v-for="message in messages" :key="message" :message="message.text" :background="message.bg"
                    @on-toast-expired="removeMessage(message)"/>
    </div>
  </div>
</template>
<script setup>
import TopBar from "@/components/TopBar"
import {useRoute} from "vue-router";
import {computed, onMounted, ref, watch} from "vue";
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import emitter from "@/eventbus"
import MessageToast from "@/components/Common/MessageToast";

const route = useRoute()
const authStore = useAuthStore()
const loading = ref(false)
const error = ref(null)
const messages = ref([])

watch(route, async (to) => {
  document.title = to.meta.title || process.env.VUE_APP_TITLE
})

onMounted(() => getUser())

const shouldDisplayContent = computed(() => {
  return authStore.user != null || route.name === 'login'
})

const shouldDisplayTopBar = computed(() => {
  return authStore.user != null && route.meta.topBar
})

function getUser() {
  loading.value = true
  axios
      .get('/user/me')
      .then(response => authStore.user = response.data)
      .catch(exception => {
        error.value = exception.message
      })
      .finally(() => loading.value = false)
}

function removeMessage(message) {
  messages.value = messages.value.filter(value => value !== message)
}

emitter.on('toast', message => messages.value.push(message))
</script>

<style>
</style>
