<template>
  <div class="dropdown">
    <button class="btn btn-primary dropdown-toggle" type="button" id="submissionTypeFilterDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false" data-bs-auto-close="outside" :disabled="loading || submissionTypes.length === 0">
      {{ $t('submission.type.name') }}
      <span v-if="selectedTypes.length > 0" class="badge bg-danger">{{ selectedTypes.length }}</span>
    </button>
    <ul class="dropdown-menu" aria-labelledby="submissionTypeFilterDropdown">
      <li v-for="enrichedType in enrichedTypes" :key="enrichedType.submissionType.id">
        <a class="dropdown-item" href="#" :class="enrichedType.active"
           @click.prevent="toggleSubmissionType(enrichedType.submissionType.id)">
          {{ enrichedType.submissionType.name }}
        </a>
      </li>
    </ul>
  </div>
</template>
<script setup>
import {computed, onMounted, ref} from "vue";
import axios from "axios";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const submissionTypes = ref([])
const selectedTypes = ref([])
const loading = ref(false)

const emit = defineEmits(['onSubmissionTypeFilterChanged'])

onMounted(() => loadSubmissionTypes())

function loadSubmissionTypes() {
  loading.value = true
  axios
      .get('/submission/types')
      .then(response => submissionTypes.value = response.data)
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}

function toggleSubmissionType(id) {
  if (selectedTypes.value.includes(id)) {
    selectedTypes.value = selectedTypes.value.filter(testType => testType !== id)
  } else {
    selectedTypes.value.push(id)
  }
  emit('onSubmissionTypeFilterChanged', selectedTypes.value)
}

const enrichedTypes = computed(() => {
  if (submissionTypes.value.length === 0) {
    return []
  }

  const enrichedTypes = []
  for (const type of submissionTypes.value) {
    enrichedTypes.push({submissionType: type, active: selectedTypes.value.includes(type.id) ? 'active' : ''})
  }

  return enrichedTypes
})
</script>
<style>

</style>