import {createApp} from 'vue'
import App from './App.vue'

import "bootstrap"
import "../scss/custom.scss"
import "bootstrap-icons/font/bootstrap-icons.css"
import {createI18n} from "vue-i18n";
import axios from "axios";
import {DateTime} from "luxon";
import {vue3Debounce} from "vue-debounce";
import {createPinia} from "pinia/dist/pinia";
import {useAuthStore} from "@/stores/auth";
import messages_en from "@/translations/en.json"
import messages_de from "@/translations/de.json"
import messages_fr from "@/translations/fr.json"
import messages_es from "@/translations/es.json"
import messages_pt from "@/translations/pt.json"
import getUserLocale from "get-user-locale";
import {Settings} from "luxon"
import {handleAxiosRequest, handleAxiosResponse} from "@/auth";
import router from "@/router";


const app = createApp(App)


/**
 * Pinia
 * @type {Pinia}
 */
const pinia = createPinia()
app.use(pinia)

const i18n = createI18n({
    legacy: false,
    locale: 'de',
    fallbackLocale: 'de',
    messages: {
        en: messages_en, de: messages_de, fr: messages_fr, es: messages_es, pt: messages_pt
    }
})
app.use(i18n)


app.use(router)

router.beforeEach((to) => {
    const authStore = useAuthStore()
    if ((to.meta.authRequired == null || to.meta.authRequired) && authStore.refresh_token == null) {
        return {path: '/login', query: {origin: to.path}}
    }

    if (to.meta.topBar == null) {
        to.meta.topBar = true
    }
})


axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL
axios.interceptors.request.use(handleAxiosRequest)
axios.interceptors.response.use((response) => {
    return response
}, handleAxiosResponse)

axios.interceptors.request.use(config => {
    const authStore = useAuthStore()

    if (authStore.user != null && authStore.user.locale !== 'auto') {
        config.headers['Locale'] = authStore.user.locale
    } else {
        const userLocale = getUserLocale()
        config.headers['Locale'] = userLocale.slice(0, 2)
    }

    return config
})

app.config.globalProperties.$luxon = DateTime

const authStore = useAuthStore()
authStore.$subscribe((mutation, state) => {
    if (state.user == null) {
        return
    }

    if (state.user.locale !== 'auto') {
        i18n.global.locale.value = state.user.locale
        Settings.defaultLocale = state.user.locale
    } else {
        const userLocale = getUserLocale()
        i18n.global.locale.value = userLocale.slice(0, 2)
        Settings.defaultLocale = userLocale.slice(0, 2)
    }
})

app.directive('debounce', vue3Debounce({lock: true}))
app.mount('#app')