export const Languages = new Map([
    ['de', 'Deutsch'],
    ['en', 'English'],
    ['fr', 'Français'],
    ['es', 'Español'],
    ['pt', 'Português']
])

export const FiletypeToIcon = new Map([
    ['jpg', 'bi-filetype-jpg'],
    ['jpeg', 'bi-filetype-jpg'],
    ['bmp', 'bi-filetype-bmp'],
    ['png', 'bi-filetype-png'],
    ['pdf', 'bi-filetype-pdf'],
    ['doc', 'bi-filetype-doc'],
    ['docx', 'bi-filetype-docx'],
    ['xls', 'bi-filetype-xls'],
    ['xlsx', 'bi-filetype-xlsx'],
    ['txt', 'bi-filetype-txt']
])