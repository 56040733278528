<template>
  <div class="d-flex mb-2">
    <div class="border-end border-4 border-light pe-2 me-2">
      <ProfileImage :user="comment.creator" :size="42"/>
    </div>
    <div class="flex-fill">
      <div>
        <span class="small fw-bold">{{ creatorName }}</span>
        <span class="small text-secondary ms-2"
              :title="DateTime.fromISO(comment.created_at).toLocaleString(DateTime.DATETIME_MED)">
          {{ relativeTime }}
        </span>
        <span v-if="comment.public" class="badge bg-primary ms-1">{{ $t('common.public') }}</span>
      </div>
      <div>{{ comment.content }}</div>
    </div>
  </div>
</template>
<script setup>
import ProfileImage from "@/components/Common/ProfileImage"
import {DateTime} from "luxon";
import {computed, onMounted, onUnmounted, ref} from "vue";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const props = defineProps({
  comment: Object
})

const relativeTime = ref(null)
let interval = null

onMounted(() => {
  updateRelativeTime()
  interval = setInterval(updateRelativeTime, 30 * 1000)
})

onUnmounted(() => {
  clearInterval(interval)
})

const creatorName = computed(() => {
  if (props.comment.creator) {
    return `${props.comment.creator.first_name} ${props.comment.creator.last_name}`
  }
  return i18n.t('user.examinations_office')
})

function updateRelativeTime() {
  if (DateTime.fromISO(props.comment.created_at).diffNow().as("seconds") > -60) {
    relativeTime.value = i18n.t('common.just_now')
  } else {
    relativeTime.value = DateTime.fromISO(props.comment.created_at)
        .toRelative()
  }
}
</script>
<style>

</style>