<template>
  <div class="d-flex justify-content-center align-items-center vh-100">
    <div class="card shadow" style="min-width: 30%;">
      <div class="card-body p-4">
        <div class="d-flex align-items-center justify-content-center">
          <img class="ms-4 me-4 mb-4" src="images/wiso_button_en.png" alt="wiso logo" style="max-width: 200px;"/>
        </div>
        <div class="ps-2 pe-2">
          <h4>{{ $t('login.caption') }}</h4>
          {{ $route.query.origin }}
          <div v-if="error" class="alert alert-danger alert-dismissible fade show" role="alert">
            {{ error }}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"
                    @click="error.active = false"></button>
          </div>
          <form @submit.prevent="login">
            <div class="mb-3">
              <label for="username" class="form-label">{{ $t('login.username') }}</label>
              <input v-model="form.username" type="text" class="form-control" id="username"
                     required :disabled="loading">
            </div>
            <div class="mb-4">
              <label for="password" class="form-label">{{ $t('login.password') }}</label>
              <input v-model="form.password" type="password" class="form-control" id="password" :disabled="loading"
                     required>
            </div>
            <div class="d-flex justify-content-center">
              <button type="submit" class="btn btn-primary" :disabled="loading">
                    <span v-if="loading" class="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span>
                {{ $t('login.login') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {ref} from "vue";
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

const route = useRoute()
const router = useRouter()
const i18n = useI18n()
const authStore = useAuthStore()
const form = ref({username: '', password: '', stay_logged_in: false})
const loading = ref(false)
const error = ref(null)

async function login() {
  loading.value = true
  error.value = null
  const formData = new FormData()
  formData.append('username', form.value.username)
  formData.append('password', form.value.password)
  formData.append('grant_type', 'password')

  try {
    const loginResponse = await axios.post('/auth/token', formData,
        {headers: {'Content-Type': 'multipart/form-data'}})
    authStore.access_token = loginResponse.data.access_token
    authStore.refresh_token = loginResponse.data.refresh_token

    const userResponse = await axios.get('/user/me')
    authStore.user = userResponse.data

    if (route.query.origin != null) {
      await router.push(`${route.query.origin}`)
    } else {
      await router.push('/')
    }
  } catch (exception) {
    if (exception['response'] == null) {
      return
    }
    switch (exception.response.status) {
      case 401:
        error.value = i18n.t('login.error_credentials')
        break
      default:
        error.value = i18n.t('login.error_generic')
        break
    }
  } finally {
    loading.value = false
  }
}
</script>
<style>

</style>