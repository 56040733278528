<template>
  <div class="d-flex align-items-center">
    <div class="fs-6 fw-bold text-nowrap text-truncate">
      {{ submission.token }}:
      {{ submission.submission_type.name }}
    </div>
    <div v-if="submission.state" class="badge ms-2" :class="stateColor">
      {{ $t(`submission.state.values.${submission.state}`) }}
    </div>
    <div class="ms-auto small">
      {{ $luxon.fromISO(submission.created_at).toLocaleString($luxon.DATE_SHORT) }}
    </div>
  </div>

  <div class="d-flex align-items-center mt-2">
    <span class="small me-1 text-secondary">{{ $t('common.created_by') }}</span>
    <ProfileImage :size="26" :user="submission.creator"/>
    <!--<span class="small text-secondary ms-1">{{ submission.creator.first_name }} {{ submission.creator.last_name }}</span>-->
    <template v-if="submission.assignees">
      <span class="ms-2 me-2 text-secondary">&vert;</span>
      <span v-if="submission.assignees.length > 0" class="small me-3 text-secondary">{{
          $t('common.assigned_to')
        }}</span>
      <span v-else class="small text-secondary">
        <span class="text-secondary">{{ $t('common.unassigned') }}</span>
      </span>
      <div v-for="assignee in submission.assignees" :key="assignee.id" style="margin-left: -0.7rem;">
        <ProfileImage :size="26" :user="assignee"/>
      </div>
    </template>
    <span class="ms-2 me-2 text-secondary">&vert;</span>
    <span class="text-secondary">
      <i class="bi bi-paperclip"></i><span class="small">{{ submission.documents.length }}</span>
    </span>
  </div>

</template>
<script setup>
import ProfileImage from "@/components/Common/ProfileImage";
import {computed} from "vue";

const props = defineProps({
  submission: Object
})

const stateColor = computed(() => {
  switch (props.submission.state) {
    case 'in_creation':
    case 'created':
    default:
      return 'bg-info'
    case 'in_progress':
      return 'bg-progress'
    case 'information_needed':
      return 'bg-warning'
    case 'done':
      return 'bg-success'
    case 'rejected':
      return 'bg-danger'
    case 'archived':
      return 'bg-dark'
  }
})
</script>
<style>

</style>