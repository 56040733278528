<template>
  <div class="mt-4">
    <div class="list-group mt-2">
      <div class="list-group-item bg-light d-flex align-items-center">
        <span class="fw-bold">{{ $t('submission.type.list.caption') }}</span>
        <div v-if="loading" class="spinner-border spinner-border-sm ms-auto" role="status">
          <span class="visually-hidden">{{ $t('common.loading') }}</span>
        </div>
      </div>
      <SubmissionTypeEntry v-for="submissionType in submissionTypes" :key="submissionType.id"
                           :submission-type="submissionType">

      </SubmissionTypeEntry>
      <div v-if="submissionTypes.length === 0" class="list-group-item d-flex align-items-centern">
        <span class="pt-2 pb-2">{{ $t('submission.type.list.empty') }}</span>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-2">
      <router-link to="/submission/type/" class="btn btn-primary">{{ $t('common.new') }}</router-link>
    </div>
  </div>
</template>
<script setup>
import SubmissionTypeEntry from "@/components/Administration/SubmissionType/SubmissionTypeEntry";
import {onMounted, ref} from "vue";
import axios from "axios";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const submissionTypes = ref([])
const loading = ref(false)

onMounted(() => loadSubmissionTypes())

function loadSubmissionTypes() {
  loading.value = true
  axios
      .get('/submission/types')
      .then(response => submissionTypes.value = response.data)
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}
</script>
<style>

</style>