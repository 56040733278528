<template>
  <div v-if="showFilters" class="d-flex mb-4">
    <input v-debounce:400ms.lock="filterSubmissions" type="text" class="form-control"
           :placeholder="$t('submission.list.filter.placeholder')">
    <StateFilter @on-state-filter-changed="onStateFilterChanged" class="ms-2"/>
    <SubmissionTypeFilter @on-submission-type-filter-changed="onSubmissionTypeFilterChanged" class="ms-2"/>
  </div>

  <div class="list-group">
    <router-link v-for="submission in filteredAndSortedSubmissions" :key="submission.id"
                 class="list-group-item list-group-item-action"
                 :to="createLinkToSubmission(submission)">
      <SubmissionEntry :submission="submission"/>
    </router-link>
  </div>

  <div v-if="submissions.length === 0 && !error && !loading" class="text-secondary">
    {{ $t('submission.list.empty') }}
  </div>

  <div v-if="error" class="alert alert-danger">{{ error }}</div>

  <div v-if="loading && submissions.length === 0" class="list-group">
    <div v-for="n in 2" :key="n" class="list-group-item">
      <SubmissionListEntryPlaceholder/>
    </div>
  </div>

  <div v-if="submissions.length > filteredAndSortedSubmissions.length" class="d-flex justify-content-center mt-1">
    <a href="#" class="fst-italic small" @click.prevent="expanded = true">
      {{ $t('submission.list.expand', {count: submissions.length - filteredAndSortedSubmissions.length}) }}
    </a>
  </div>
</template>
<script setup>
import SubmissionEntry from "@/components/Common/SubmissionList/SubmissionListEntry";
import StateFilter from "@/components/Common/SubmissionList/Filter/StateFilter";
import SubmissionTypeFilter from "@/components/Common/SubmissionList/Filter/SubmissionTypeFilter";
import SubmissionListEntryPlaceholder from "@/components/Common/SubmissionList/SubmissionListEntryPlaceholder";

import {computed, onMounted, ref} from "vue";
import axios from "axios";
import {useI18n} from "vue-i18n";
import {DateTime} from "luxon";
import {useAuthStore} from "@/stores/auth";
import {SubmissionStates} from "@/enums";

const i18n = useI18n()
const authStore = useAuthStore()

const submissions = ref([])
const filterText = ref(null)
const filterStates = ref([])
const filterSubmissionTypes = ref([])
const loading = ref(false)
const error = ref(null)
const expanded = ref(false)

const props = defineProps({
  showFilters: {
    type: Boolean,
    default: true
  },
  filterAssignees: {
    type: Array,
    default: null
  },
  initFilterStates: {
    type: Array,
    default: null
  },
  initialSize: {
    type: Number,
    default: 5
  }
})

onMounted(() => {
  filterStates.value = props.initFilterStates
  loadSubmissions()
})

const filteredAndSortedSubmissions = computed(() => {
  let result = submissions.value
  result.sort((a, b) => DateTime.fromISO(b.updated_at).toMillis() - DateTime.fromISO(a.updated_at).toMillis())

  if (!expanded.value) {
    result = result.slice(0, props.initialSize)
  }

  return result
})

function loadSubmissions() {
  error.value = null
  loading.value = true
  buildRequest()
      .then(response => submissions.value = response.data.filter(submission => submission.state !== 1))
      .catch(() => {
        submissions.value = []
        error.value = i18n.t('submission.list.error')
      })
      .finally(() => loading.value = false)
}

function buildRequest() {
  if (authStore.isEditor) {
    return axios.get('/submissions/search', {
      params: {
        filter_text: filterText.value,
        filter_states: filterStates.value,
        filter_submission_type_ids: filterSubmissionTypes.value,
        filter_assigned_to_user_ids: props.filterAssignees
      }
    })
  } else {
    return axios.get('/submissions/user_search', {
      params: {
        filter_text: filterText.value,
        filter_states: filterStates.value
      }
    })
  }
}

function onStateFilterChanged(filters) {
  filterStates.value = filters
  loadSubmissions()
}

function onSubmissionTypeFilterChanged(ids) {
  filterSubmissionTypes.value = ids
  loadSubmissions()
}

function filterSubmissions(text) {
  if (text.length > 0) {
    filterText.value = text
  } else {
    filterText.value = null
  }
  loadSubmissions()
}

function createLinkToSubmission(submission) {
  if (authStore.isUser || (submission.state != null && submission.state === SubmissionStates.in_creation)) {
    return `/submission/${submission.id}/ammend`
  } else {
    return `/submission/${submission.id}`
  }
}

</script>
<style>

</style>