import {createRouter, createWebHistory} from "vue-router";
import SubmissionDashboard from "@/components/Dashboard/SubmissionDashboard";
import UserLogin from "@/components/UserLogin";
import UserProfile from "@/components/UserProfile";
import DocumentTypeList from "@/components/Administration/DocumentType/DocumentTypeList";
import DocumentTypeForm from "@/components/Administration/DocumentType/DocumentTypeForm";
import SubmissionList from "@/components/Common/SubmissionList/SubmissionList";
import SubmissionSwitchboard from "@/components/Submission/Switchboard/SubmissionSwitchboard";
import SubmissionTypeList from "@/components/Administration/SubmissionType/SubmissionTypeList";
import SubmissionTypeForm from "@/components/Administration/SubmissionType/SubmissionTypeForm";
import SubmissionWizardType from "@/components/SubmissionWizard/SubmissionWizardType";
import SubmissionForm from "@/components/Submission/Form/SubmissionForm";
import TemplateList from "@/components/Administration/Template/TemplateList"
import TemplateForm from "@/components/Administration/Template/TemplateForm"
import NotAllowed from "@/components/NotAllowed";
import NotFound from "@/components/NotFound";
import UserList from "@/components/Administration/User/UserList"
import UserForm from "@/components/Administration/User/UserForm"

export default createRouter({
    history: createWebHistory(), routes: [
        {path: '/', component: SubmissionDashboard},
        {path: '/login', component: UserLogin, name: 'login', meta: {topBar: false, authRequired: false}},
        {path: '/profile', component: UserProfile},

        {path: '/document/types', component: DocumentTypeList},
        {path: '/document/type', component: DocumentTypeForm},
        {path: '/document/type/:id', component: DocumentTypeForm},

        {path: '/submissions', component: SubmissionList},
        {path: '/submission/:id', component: SubmissionSwitchboard},
        {path: '/submission/:submission_id/create', component: SubmissionForm},
        {path: '/submission/:submission_id/ammend', component: SubmissionForm},

        {path: '/config/users', component: UserList},
        {path: '/config/user/:id', component: UserForm},

        {path: '/submission/types', component: SubmissionTypeList},
        {path: '/submission/type', component: SubmissionTypeForm},
        {path: '/submission/type/:id', component: SubmissionTypeForm},

        {path: '/wizard/type', component: SubmissionWizardType},

        {path: '/templates', component: TemplateList},
        {path: '/template/:id', component: TemplateForm},

        {path: '/forbidden', component: NotAllowed},
        {path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound}
    ]
})