<template>
  <div class="d-flex align-items-center">
    <span class="fs-5 fw-bold">{{ $t('filemanager.attached_files') }}</span>
    <div v-if="taskRunning" class="spinner-border spinner-border-sm ms-auto text-primary me-2"
         role="status"
         aria-hidden="true">

    </div>
  </div>
  <div class="list-group mt-2">
    <DocumentManagerItem v-for="document in documents" :key="document.id" :document="document"
                         :editable="enableDeletion"
                         @on-document-deleted="onDocumentDeleted"/>

    <div v-if="loading">
      <DocumentManagerPlaceholder v-for="n in 3" :key="n"/>
    </div>

    <div v-if="error" class="list-group-item">
      {{ error }}
    </div>

    <div v-if="!loading && documents.length === 0 && !enableUpload">
      {{ $t('filemanager.empty') }}
    </div>

    <div v-if="enableUpload && !error && !loading" class="list-group-item list-group-item-action p-0">
      <DocumentManagerDropZone @files-dropped="filesDropped" #default="{isHovered}">
        <div class="d-flex justify-content-center align-items-center"
             :class="{'border border-3 border-primary': isHovered}" style="height: 100pt;">
          <i class="bi bi-cloud-upload display-3 text-muted"></i>
        </div>
      </DocumentManagerDropZone>
    </div>
  </div>
  <div v-if="documents.length > 1" class="mt-2 d-flex justify-content-center">
    <a :href="`${axios.defaults.baseURL}/submission/${submissionId}/documents/download`"
       class="link-primary fst-italic" role="link" :aria-disabled="taskRunning">
      {{ $t('filemanager.zip') }}
    </a>
  </div>
</template>
<script setup>
import DocumentManagerItem from "@/components/DocumentManager/DocumentManagerItem";
import DocumentManagerDropZone from "@/components/DocumentManager/DocumentManagerDropZone";
import {onMounted, ref, watch} from "vue";
import axios from "axios";
import {DocumentStatus} from "@/enums";
import {v4 as uuidv4} from 'uuid'
import {documentSuffixToType} from "@/config";
import DocumentManagerPlaceholder from "@/components/DocumentManager/DocumentManagerPlaceholder";
import {useI18n} from "vue-i18n";
import emitter from "@/eventbus";
import prettyBytes from "pretty-bytes";
import {isDocumentAllowed} from "@/util";

const documents = ref([])
const taskRunning = ref(false)
const loading = ref(false)
const taskScheduled = ref(false)
const submissionId = ref(null)
const error = ref(null)

const i18n = useI18n()
const emit = defineEmits(['onDocumentsChanged', 'onTaskStateChanged'])
const props = defineProps({
  submission: Object,
  initSubmissionId: String,
  enableUpload: {
    type: Boolean,
    default: false
  },
  enableDeletion: {
    type: Boolean,
    default: false
  }
})

watch(documents.value, () => {
  emit('onDocumentsChanged')
})

onMounted(() => {
  if (props.submission) {
    submissionId.value = props.submission.id
    if (props.submission.documents) {
      processDocuments(props.submission.documents)
    }
  } else if (props.initSubmissionId) {
    submissionId.value = props.initSubmissionId
    loadDocuments()
  }
})

emitter.on('retry', () => {
  if (error.value) loadDocuments()
})

function loadDocuments() {
  error.value = false
  loading.value = true
  axios
      .get(`/submission/${submissionId.value}/documents`)
      .then(response => processDocuments(response.data))
      .catch(() => {
        error.value = i18n.t('filemanager.error')
      })
      .finally(() => loading.value = false)
}

function processDocuments(vanillaDocuments) {
  for (const document of vanillaDocuments) {
    documents.value.push({
      id: document.id,
      name: document.name,
      size: document.size,
      progress: 100,
      status: DocumentStatus.uploaded,
      locked: document.locked
    })
  }
}

async function filesDropped(files) {
  for (const droppedFile of files) {
    documents.value.push({
      id: uuidv4(),
      name: droppedFile.name,
      size: droppedFile.size,
      progress: 0,
      status: DocumentStatus.dropped,
      file: droppedFile,
      locked: false
    })
  }
  taskScheduled.value = true
  await uploadTask()
}

async function uploadTask() {
  if (taskRunning.value) {
    return
  }

  taskRunning.value = true
  emit('onTaskStateChanged', true)
  while (taskScheduled.value) {
    taskScheduled.value = false
    taskLoop:
        for (let document of documents.value) {
          if (document.status !== DocumentStatus.dropped) {
            continue
          }

          const suffix = document.name.split('.').pop()
          if (!isDocumentAllowed(suffix)) {
            document.status = DocumentStatus.disallowed
            emitter.emit('toast', {
              bg: 'bg-danger',
              text: i18n.t('filemanager.upload_error_type', {document: document.name})
            })
            removeDocumentFromList(document.id)

            continue
          }

          for (const testDocument of documents.value) {
            if (testDocument.id !== document.id && testDocument.name === document.name) {
              document.status = DocumentStatus.conflict
              emitter.emit('toast', {
                bg: 'bg-danger',
                text: i18n.t('filemanager.upload_error_conflict', {document: document.name})
              })
              removeDocumentFromList(document.id)

              continue taskLoop
            }
          }


          if (document.size > maxSizeForType(suffix)) {
            document.status = DocumentStatus.tooBig
            emitter.emit('toast', {
              bg: 'bg-danger',
              text: i18n.t('filemanager.upload_error_size', {
                document: document.name,
                size: prettyBytes(maxSizeForType(suffix))
              })
            })
            removeDocumentFromList(document.id)

            continue
          }

          let formData = new FormData()
          formData.append('submission_id', submissionId.value)
          formData.append('file', document.file)

          try {
            document.status = DocumentStatus.uploading
            const fileResponse = await axios.post('/document', formData, {
              headers: {"Content-Type": "multipart/form-data"},
              onUploadProgress: function (event) {
                this.progress = (event.loaded * 100) / event.total
              }.bind(document)
            })
            document.id = fileResponse.data.id
            document.status = DocumentStatus.uploaded
            emitter.emit('toast', {
              text: i18n.t('filemanager.upload_success', {document: document.name})
            })
          } catch (exception) {
            document.status = DocumentStatus.error
            emitter.emit('toast', {
              bg: 'bg-danger',
              text: i18n.t('filemanager.upload_error_network', {document: document.name})
            })
            removeDocumentFromList(document.id)
          }
        }
  }
  taskRunning.value = false
  emit('onTaskStateChanged', false)
}

function maxSizeForType(suffix) {
  return documentSuffixToType[suffix].limit
}

function onDocumentDeleted(id) {
  removeDocumentFromList(id)
}

function removeDocumentFromList(id) {
  documents.value = documents.value.filter(document => document.id !== id)
}

</script>
<style>

</style>