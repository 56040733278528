<template>
  <div class="d-flex font-monospace">
    <div class="small fw-bold text-secondary text-nowrap">
      [{{ DateTime.fromISO(event.created_at).toLocaleString(DateTime.DATETIME_SHORT) }}]
    </div>
    <div class="ms-2 text-black small" v-html="eventText"/>
  </div>
</template>
<script setup>
import {DateTime} from "luxon";
import {computed} from "vue";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const props = defineProps({
  event: Object
})

const eventText = computed(() => {
  const recipient_name = props.event.recipient ?
      `${props.event.recipient.first_name} ${props.event.recipient.last_name}` : 'Redacted'
  const originator_name = props.event.originator ?
      `${props.event.originator.first_name} ${props.event.originator.last_name}` : 'Redacted'
  switch (props.event.event_type) {
    case 'submission_submitted':
    case 'submission_created':
      return i18n.t(`submission.events.types.${props.event.event_type}`, {
        creator_name: originator_name
      })
    case 'submission_state_changed':
      return i18n.t(`submission.events.types.${props.event.event_type}`, {
        old_state: i18n.t(`submission.state.values.${props.event.old_state}`),
        new_state: i18n.t(`submission.state.values.${props.event.new_state}`),
        creator_name: originator_name
      })
    case 'document_created':
    case 'document_removed':
      return i18n.t(`submission.events.types.${props.event.event_type}`, {
        document_name: props.event.content,
        creator_name: originator_name
      })
    case 'comment_created':
      return i18n.t(`submission.events.types.${props.event.event_type}`, {
        comment: props.event.content,
        creator_name: originator_name
      })
    case 'assignee_added':
    case 'assignee_removed':
      return i18n.t(`submission.events.types.${props.event.event_type}`, {
        recipient_name: recipient_name,
        creator_name: originator_name
      })
    default:
      return props.event.event_type
  }
})
</script>
<style>

</style>