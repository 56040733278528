<template>
  <span class="fs-5 fw-bold">{{ $t('submission.events.caption') }}</span>
  <div class="mt-2 bg-light rounded p-2">
    <div v-if="loading && sortedEvents.length === 0">
      <SubmissionEventsPlaceholder v-for="n in 10" :key="n"/>
    </div>

    <template v-if="!error">
      <SubmissionEventsItem v-for="event in sortedEvents" :key="event.id" :event="event"/>
    </template>

    <div v-if="error">
      {{ error }}
    </div>
  </div>
</template>
<script setup>
import {computed, onMounted, ref} from "vue";
import {DateTime} from "luxon";
import axios from "axios";
import SubmissionEventsItem from "@/components/Submission/Events/SubmissionEventsItem";
import SubmissionEventsPlaceholder from "@/components/Submission/Events/SubmissionEventsPlaceholder";
import {useI18n} from "vue-i18n";

const props = defineProps({
  submission: Object,
  initSubmissionId: String
})

defineExpose({
  loadLogEntries
})

const i18n = useI18n()
const events = ref([])
const submissionId = ref(null)
const loading = ref(false)
const error = ref(null)

const sortedEvents = computed(() => {
  const sorted = events.value
  sorted.sort(function (a, b) {
    return DateTime.fromISO(b.created_at).toMillis() - DateTime.fromISO(a.created_at).toMillis()
  })

  return sorted
})

onMounted(() => {
  if (props.submission) {
    submissionId.value = props.submission.id
    events.value = props.submission.events
  } else if (props.initSubmissionId) {
    submissionId.value = props.initSubmissionId
    loadLogEntries()
  }
})

function loadLogEntries() {
  error.value = false
  loading.value = true
  axios
      .get(`/submission/${submissionId.value}/events`)
      .then(response => events.value = response.data)
      .catch(() => {
        error.value = i18n.t('submission.logs.error')
      })
      .finally(() => loading.value = false)
}
</script>
<style>

</style>