import {defineStore} from "pinia/dist/pinia"
import {useLocalStorage} from "@vueuse/core/index";
import {Role} from "@/enums";

export const useAuthStore = defineStore('auth', {
    state: () => {
        return {
            access_token: null,
            refresh_token: useLocalStorage('refresh_token', null),
            user: null
        }
    },
    getters: {
        isUser: (state) => state.user.role === Role.user,
        isEditor: (state) => [Role.editor, Role.admin, Role.founder].includes(state.user.role),
        isAdmin: (state) => [Role.admin, Role.founder].includes(state.user.role),
        isFounder: (state) => state.user.role === Role.founder
    }
})