<template>
  <div class="mt-4">
    <span class="fs-4 fw-bold">{{ $t('template.list.caption') }}</span>
    <div class="list-group mt-2 mb-4">
      <router-link v-for="template in templates" :key="template.id" :to="`/template/${template.id}`"
                   class="list-group-item list-group-item-action">
        <TemplateEntry :template="template"/>
      </router-link>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import axios from "axios";
import TemplateEntry from "@/components/Administration/Template/TemplateEntry";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const loading = ref(false)
const templates = ref([])

onMounted(() => loadTemplates())

function loadTemplates() {
  loading.value = true
  axios
      .get('/templates')
      .then(response => templates.value = response.data)
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}
</script>
<style>

</style>