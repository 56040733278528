<template>
  <div class="mt-4 mb-4">
    <div class="d-flex">
      <div>
        <span class="fs-5 fw-bold">{{ $t('user.list.caption') }}</span>
        <p>These are users that have already been synchronized to the WiSo Inbox from the LDAP directory server. Users
          automatically get synchronized whenever they successfully login to the system. You can also manually
          synchronize
          a
          user to give them a role in preparation for their work.</p>
      </div>

      <form @submit.prevent="synchronizeUser">
        <div class="d-flex">
          <input v-model="synchronizeUid" type="text" class="form-control" id="synchronizeUid"
                 required :disabled="loading">
          <button type="submit" class="btn btn-primary">{{ $t('common.submit') }}</button>
        </div>
      </form>

    </div>

    <div class="d-flex mt-2">
      <input v-debounce:400ms.lock="textFilterChanged" type="text" class="form-control"
             :placeholder="$t('user.list.filter.placeholder')">
      <RoleFilter @onRoleFilterChanged="roleFilterChanged" class="ms-2"/>
    </div>
    <div class="list-group mt-4">
      <div v-if="loading" class="list-group-item d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">{{ $t('common.loading') }}</span>
        </div>
      </div>

      <router-link v-for="user in users" :key="user.id" :to="`/config/user/${user.id}`"
                   class="list-group-item list-group-item-action">
        <UserListEntry :user="user"/>
      </router-link>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import axios from "axios";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";
import RoleFilter from "@/components/Administration/User/Filter/RoleFilter";
import UserListEntry from "@/components/Administration/User/UserListEntry";

const i18n = useI18n()
const users = ref([])
const loading = ref(false)
const filterRoles = ref([])
const filterText = ref(null)
const synchronizeUid = ref(null)

onMounted(() => loadUsers())

function loadUsers() {
  loading.value = true
  axios
      .get(`/user/search`, {params: {filter_roles: filterRoles.value, filter_text: filterText.value}})
      .then(response => {
        users.value = response.data.users
      })
      .catch(() => {
        emitter.emit('toast', {bg: 'bg-danger', text: i18n.t('common.error')})
      })
      .finally(() => loading.value = false)
}

function synchronizeUser() {
  loading.value = true
  axios
      .post(`/user/${synchronizeUid.value}`)
      .then(() => {
        emitter.emit('toast', {text: i18n.t('common.ok')})
        loadUsers()
      })
      .catch(() => {
        emitter.emit('toast', {bg: 'bg-danger', text: i18n.t('common.error')})
      })
      .finally(() => loading.value = false)
}

function roleFilterChanged(roles) {
  filterRoles.value = roles
  loadUsers()
}

function textFilterChanged(text) {
  if (text.length < 3) {
    if (filterText.value.length > 0) {
      filterText.value = ''
      loadUsers()
    }

    return
  }

  filterText.value = text
  loadUsers()
}

</script>
<style>

</style>