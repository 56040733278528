<template>
  <div class="dropdown">
    <button class="btn btn-primary dropdown-toggle" type="button" id="roleFilterDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false" data-bs-auto-close="outside">
      {{ $t('common.role') }}
      <span v-if="selectedRoles.length > 0" class="badge bg-danger">{{ selectedRoles.length }}</span>
    </button>
    <ul class="dropdown-menu" aria-labelledby="roleFilterDropdown">
      <li v-for="role in enrichedRoles" :key="role.name">
        <a class="dropdown-item" :class="role.active" href="#" @click.prevent="toggleRoleFilter(role.name)">
          {{ $t(`user.roles.${role.name}`) }}
        </a>
      </li>
    </ul>
  </div>
</template>
<script setup>
import {computed, ref} from "vue";
import {Role} from "@/enums";

const selectedRoles = ref([])

const emit = defineEmits(['onRoleFilterChanged'])

const enrichedRoles = computed(() => {
  const enrichedRoles = []
  for (const role of Object.values(Role)) {
    enrichedRoles.push({name: role, active: selectedRoles.value.includes(role) ? 'active' : ''})
  }

  return enrichedRoles
})

function toggleRoleFilter(role) {
  if (selectedRoles.value.includes(role)) {
    selectedRoles.value = selectedRoles.value.filter(testRole => testRole !== role)
  } else {
    selectedRoles.value.push(role)
  }
  emit('onRoleFilterChanged', selectedRoles.value)
}

</script>
<style>

</style>