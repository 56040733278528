<template>
  <div class="p-4">
    <h3>{{ $t('wizard.type.caption') }}</h3>

    <div v-if="loading" class="list-group">
      <div class="row row-cols-1 row-cols-md-3 g-4">
        <div class="col" v-for="index in 10" :key="index">
          <SubmissionTypePlaceholder/>
        </div>
      </div>
    </div>

    <div v-else class="list-group">
      <div class="row row-cols-1 row-cols-md-2 g-4">
        <div class="col" v-for="submissionType in submissionTypes" :key="submissionType.id">
          <SubmissionTypeCard :submission-type="submissionType"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import SubmissionTypePlaceholder from "@/components/SubmissionWizard/ListItems/SubmissionTypePlaceholder";
import SubmissionTypeCard from "@/components/SubmissionWizard/ListItems/SubmissionTypeCard";
import {onMounted, ref} from "vue";
import axios from "axios";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const submissionTypes = ref([])
const loading = ref(false)

onMounted(() => {
  loading.value = true
  axios
      .get('/submission/types')
      .then(response => submissionTypes.value = response.data)
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
})
</script>
<style>

</style>