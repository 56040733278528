<template>
  <div class="alert-danger m-5">
    403 - Forbidden
  </div>
</template>
<script setup>

</script>
<style>

</style>