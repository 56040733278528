<template>
  <div class="list-group-item list-group-item-action" @click.prevent="onFileClick" style="cursor: pointer;">
    <div class="d-flex align-items-center">
      <i class="bi fs-3 ms-1" :class="icon"></i>
      <span class="fw-bold ms-3" :class="fileNameClass">{{ document.name }}</span>
      <span class="text-secondary flex-grow-1 ms-2" :class="fileSizeClass">{{ fileSize }}</span>

      <div v-if="showProgress" class="col-1">
        <div class="progress">
          <div class="progress-bar" :style="`width: ${document.progress}%;`" role="progressbar" aria-valuenow="0"
               aria-valuemin="0"
               aria-valuemax="100"></div>
        </div>
      </div>

      <div v-else-if="showDeleteButton" class="col-auto me-2" @click.stop.prevent="onDeleteClick">
        <i class="bi bi-trash"></i>
      </div>

      <div v-else-if="loading" class="spinner-border spinner-border-sm text-primary me-2" role="status">
        <span class="visually-hidden">{{ $t('common.loading') }}</span>
      </div>

    </div>
  </div>
</template>
<script setup>
import {computed, ref} from "vue";
import {FiletypeToIcon} from "@/constants";
import {DocumentStatus} from "@/enums";
import prettyBytes from "pretty-bytes";
import axios from "axios";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";
import {isDocumentAllowed} from "@/util";

const props = defineProps({
  document: Object,
  editable: Boolean
})
const i18n = useI18n()

const emit = defineEmits(['onDocumentDeleted'])

const loading = ref(false)

const icon = computed(() => {
  const suffix = props.document.name.split('.').pop()
  const icon = FiletypeToIcon.get(suffix)
  if (icon == null) {
    return `bi-file-earmark${!isDocumentAllowed(suffix) ? ' text-danger' : ''}`
  }

  return `${icon}${!isDocumentAllowed(suffix) ? ' text-danger' : ''}`
})

const fileNameClass = computed(() => {
  return [DocumentStatus.conflict, DocumentStatus.error].includes(props.document.status) ? 'text-danger fw-bold' : ''
})

const fileSize = computed(() => {
  return prettyBytes(props.document.size)
})

const fileSizeClass = computed(() => {
  return [DocumentStatus.tooBig, DocumentStatus.error].includes(props.document.status) ? 'text-danger fw-bold' : ''
})

const filePath = computed(() => {
  return `${axios.defaults.baseURL}/document/${props.document.id}/download`
})

const showProgress = computed(() => {
  return [DocumentStatus.dropped, DocumentStatus.uploading].includes(props.document.status)
})

const showDeleteButton = computed(() => {
  return props.editable && props.document.status !== DocumentStatus.uploading && !loading.value && !props.document.locked
})

function onDeleteClick() {
  loading.value = true
  if (props.document.status === DocumentStatus.uploaded) {
    axios
        .delete(`/document/${props.document.id}`)
        .then(() => {
          emit('onDocumentDeleted', props.document.id)
          emitter.emit('toast', {
            text: i18n.t('filemanager.remove_success', {document: document.name})
          })
        })
        .catch(() => {
          emitter.emit('toast', {
            bg: 'bg-danger',
            text: i18n.t('filemanager.remove_error', {document: document.name})
          })
        })
        .finally(() => loading.value = false)
  } else {
    emit('onDocumentDeleted', props.document.id)
  }
}

function onFileClick() {
  if (loading.value) {
    return
  }

  switch (props.document.status) {
    case DocumentStatus.disallowed:
    case DocumentStatus.error:
    case DocumentStatus.conflict:
    case DocumentStatus.tooBig:
      emit('onDocumentDeleted', props.document.id)
      break
    case DocumentStatus.uploaded:
      window.location = filePath.value
      break
    case DocumentStatus.uploading:
    case DocumentStatus.dropped:
    default:
      break
  }
}
</script>
<style>

</style>