<template>
  <div class="alert-danger m-5">
    404 - Not Found
  </div>
</template>
<script setup>

</script>
<style>

</style>