<template>
  <div class="list-group">
    <div v-for="submission in submissions" :key="submission.id" class="list-group-item">
      <SubmissionHistoryEntry :submission="submission"/>
    </div>
  </div>

  <div v-if="submissions.length === 0 && !error && !loading" class="text-secondary">
    {{ $t('submission.list.empty') }}
  </div>

  <div v-if="error" class="alert alert-danger">{{ error }}</div>

  <div v-if="loading && submissions.length === 0" class="list-group">
    <div v-for="n in 2" :key="n" class="list-group-item">
      <SubmissionHistoryEntryPlaceholder/>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import axios from "axios";
import {useI18n} from "vue-i18n";
import SubmissionHistoryEntryPlaceholder
  from "@/components/Common/SubmissionHistory/SubmissionHistoryEntryPlaceholder.vue";
import SubmissionHistoryEntry from "@/components/Common/SubmissionHistory/SubmissionHistoryEntry.vue";

const i18n = useI18n()

const loading = ref(false)
const error = ref(null)
const submissions = ref([])

onMounted(() => {
  loadHistory()
})

function loadHistory() {
  loading.value = true
  axios
      .get('/submissions/history')
      .then(result => {
        submissions.value = result.data
      })
      .catch(() => {
        submissions.value = []
        error.value = i18n.t('submission.list.error')
      })
      .finally(() => loading.value = false)
}
</script>