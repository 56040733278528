<template>
  <nav class="navbar navbar-expand-lg bg-primary navbar-dark">
    <div class="container-fluid">
      <router-link class="navbar-brand p-0" to="/">
        <div class="d-flex align-items-center">
          <img height="36" src="/images/wiso_button_de.png" alt="unicorn"/>
          <span class="ms-1">{{ $t('common.app_name') }}</span>
        </div>
      </router-link>
      <button v-if="auth.isAdmin" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent"
              aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li v-if="auth.isAdmin" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="entitiesNavDropdown" role="button"
               data-bs-toggle="dropdown" aria-expanded="false">
              {{ $t('navigation.administration.caption') }}
            </a>
            <ul class="dropdown-menu" aria-labelledby="entitiesNavDropdown">
              <li>
                <router-link class="dropdown-item" to="/submission/types">
                  {{ $t('navigation.administration.submission_types') }}
                </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/document/types">
                  {{ $t('navigation.administration.document_types') }}
                </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/templates">
                  {{ $t('navigation.administration.templates') }}
                </router-link>
              </li>
              <li v-if="auth.isFounder">
                <router-link class="dropdown-item" to="/config/users">
                  {{ $t('navigation.administration.users') }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="navbar-text ms-2">
        <ProfileButton/>
      </div>
    </div>
  </nav>
</template>
<script setup>
import ProfileButton from "@/components/Common/ProfileButton";
import {useAuthStore} from "@/stores/auth";

const auth = useAuthStore()

</script>
<style>

</style>