<template>
  <div class="pt-4 pb-4">
    <div class="d-flex align-items-center">
      <span class="fs-4 fw-bold">{{ $t('dashboard.name') }}</span>

      <div class="ms-auto">
        <router-link to="/wizard/type" class="btn btn-primary">
          {{ $t('navigation.new_submission') }}
        </router-link>
      </div>
    </div>
    <h5>{{ $t('dashboard.stats.caption') }}</h5>
    <StatsList/>
    <div class="row row-cols-1 row-cols-md-2">
      <div class="col mt-4">
        <h5>{{ $t('dashboard.assigned_to_me') }}</h5>
        <SubmissionList :show-filters="false" :filter-assignees="[`${authStore.user.id}`]"/>
      </div>
      <div class="col mt-4">
        <h5>{{ $t('common.unassigned') }}</h5>
        <SubmissionList :show-filters="false" :filter-assignees="['']"/>
      </div>
    </div>

    <h5 class="mt-4">{{ $t('dashboard.search') }}</h5>
    <SubmissionList :show-filters="true"/>

    <h5 class="mt-4">{{ $t('dashboard.archive') }}</h5>
    <SubmissionList :show-filters="false" :init-filter-states="[SubmissionStates.archived]"/>
  </div>
</template>
<script setup>
import SubmissionList from "@/components/Common/SubmissionList/SubmissionList";
import {useAuthStore} from "@/stores/auth";
import StatsList from "@/components/Dashboard/Stats/StatsList";
import {SubmissionStates} from "@/enums";

const authStore = useAuthStore()
</script>
<style>

</style>