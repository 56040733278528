export const SubmissionStates = {
    in_creation: 'in_creation',
    created: 'created',
    in_progress: 'in_progress',
    information_needed: 'information_needed',
    done: 'done',
    rejected: 'rejected',
    archived: 'archived'
}

export const UniStatus = {
    employee: 'employee',
    student: 'student'
}

export const Role = {
    user: 'user',
    editor: 'editor',
    admin: 'admin',
    founder: 'founder'
}

export const DocumentStatus = {
    dropped: 0,
    uploading: 1,
    uploaded: 2,
    tooBig: 3,
    conflict: 4,
    error: 5,
    disallowed: 6
}