<template>
  <div class="pt-4 pb-4">
    <div v-if="submission">
      <h4>{{ submission.token }}: {{ submission.submission_type.name }}</h4>
    </div>
    <div v-else class="placeholder-glow">
      <span class="h4 placeholder rounded">{{ getRandomArbitraryString(7, 8) }}</span>
      <span class="h4 placeholder ms-1 rounded">: </span>
      <span class="h4 placeholder ms-1 rounded">{{ getRandomArbitraryString(10, 20) }}</span>
    </div>

    <SubmissionFormState :submission_id="`${route.params.id}`" @on-state-changed="onStateChanged"/>

    <div class="row row-cols-1 row-cols-md-2 mt-4">
      <div class="col">
        <span class="fs-5 fw-bold">{{ $t('submission.form.submitted_by') }}</span>
        <ProfileTag v-if="submission" class="mt-2" :user="submission.creator"/>
        <ProfileTagPlaceholder v-if="!submission" class="mt-2"/>
        <div class="d-flex align-items-baseline mt-1">
          <span class="small text-nowrap me-1">{{ $t('user.profile.enrolment') }}:</span>
          <span v-if="!submission">{{ getRandomArbitraryString(8, 10) }}</span>
          <span v-else class="fw-bold text-decoration-underline">{{ submission.creator.description }}</span>
        </div>
        <SubmissionTimesAndMetrics :submission="submission"/>
      </div>
      <div class="col">
        <SubmissionFormAssignees ref="assignees" :submission_id="`${route.params.id}`" :editable="!isArchived"
                                 @on-assignee-added="onChildChanged"
                                 @on-assignee-removed="onChildChanged"/>
      </div>
    </div>

    <div v-if="!isArchived" class="mt-4">
      <DocumentManager :init-submission-id="`${route.params.id}`"/>
    </div>

    <div class="mt-4">
      <SubmissionFormComments ref="comments" :init-submission-id="`${route.params.id}`" :editable="!isArchived"
                              :only-public="false" @on-new-comment="onChildChanged"/>
    </div>

    <div class="mt-4">
      <SubmissionFormLogs ref="logs" :init-submission-id="`${route.params.id}`"/>
    </div>
  </div>
</template>
<script setup>
import SubmissionFormComments from "@/components/Submission/Comments/SubmissionComments";
import SubmissionFormLogs from "@/components/Submission/Events/SubmissionEvents";
import DocumentManager from "@/components/DocumentManager/DocumentManager";
import SubmissionFormAssignees from "@/components/Submission/Assignees/SubmissionAssignees";
import ProfileTag from "@/components/Common/ProfileTag";
import SubmissionFormState from "@/components/Submission/Switchboard/SubmissionState";
import {computed, onMounted, ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";
import {SubmissionStates} from "@/enums";
import {getRandomArbitraryString} from "@/util";
import ProfileTagPlaceholder from "@/components/Common/ProfileTagPlaceholder";
import SubmissionTimesAndMetrics from "@/components/Submission/Switchboard/SubmissionTimesAndMetrics";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const route = useRoute()
const submission = ref(null)
const loading = ref(null)
const logs = ref()
const comments = ref()
const assignees = ref()

onMounted(() => loadSubmission())

function loadSubmission() {
  loading.value = true
  axios
      .get(`/submission/${route.params.id}`)
      .then(response => submission.value = response.data)
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}

function onChildChanged() {
  loadSubmission()
  logs.value.loadLogEntries()
}

function onStateChanged(state) {
  onChildChanged()
  comments.value.loadComments()
  if ([SubmissionStates.done, SubmissionStates.rejected].includes(state)) {
    assignees.value.loadAssignees()
  }
}

const isArchived = computed(() => {
  return submission.value !== null && submission.value.state === SubmissionStates.archived
})
</script>
<style>

</style>