<template>
  <div v-if="user" class="mt-4 mb-4">
    <span class="fs-4 bold">{{ $t('user.list.form.caption') }}</span>
    <div>{{ user.first_name }} {{ user.last_name }}</div>
    <form @submit.prevent="saveUser">
      <select v-model="form.role" class="form-select" aria-label="Default select example">
        <option v-for="role of Role" :key="role" :value="role">
          {{ $t(`user.roles.${role}`) }}
        </option>
      </select>
      <button type="submit" class="btn btn-primary" :disabled="loading">
        {{ $t('common.save') }}
      </button>
    </form>
  </div>
</template>
<script setup>

import {onMounted, ref} from "vue";
import axios from "axios";
import {useRoute, useRouter} from "vue-router";
import {Role} from "@/enums";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const user = ref(null)
const route = useRoute()
const router = useRouter()
const loading = ref(false)
const form = ref({
  role: Role.user
})

onMounted(() => loadUser())

function loadUser() {
  loading.value = true
  axios
      .get(`/user/${route.params.id}`)
      .then(response => {
        user.value = response.data
        form.value.role = user.value.role
      })
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}

function saveUser() {
  loading.value = true
  axios
      .patch(`/user/${route.params.id}`, {role: form.value.role})
      .then(() => {
        emitter.emit('toast', {
          text: i18n.t('user.list.form.save_success')
        })
        router.push('/config/users')
      })
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('user.list.form.save_error')
        })
      })
      .finally(() => loading.value = false)
}
</script>
<style>

</style>