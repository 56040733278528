export const documentType = {
    image: {
        name: 'Image',
        limit: megabytesToBytes(5)
    },
    pdf: {
        name: 'PDF',
        limit: megabytesToBytes(60)
    },
    document: {
        name: 'Document',
        limit: megabytesToBytes(60)
    },
    spreadsheet: {
        name: 'Spreadsheet',
        limit: megabytesToBytes(60)
    },
    presentation: {
        name: 'Presentation',
        limit: megabytesToBytes(60)
    },
    plain: {
        name: 'Plain text',
        limit: megabytesToBytes(2)
    },
    audio: {
        name: 'Audio',
        limit: megabytesToBytes(10)
    }
}

export const documentSuffixToType = {
    jpg: documentType.image,
    jpeg: documentType.image,
    png: documentType.image,
    bmp: documentType.image,
    gif: documentType.image,
    pdf: documentType.pdf,
    doc: documentType.document,
    docx: documentType.document,
    odt: documentType.document,
    xls: documentType.spreadsheet,
    xlsx: documentType.spreadsheet,
    ods: documentType.spreadsheet,
    ppt: documentType.presentation,
    pptx: documentType.presentation,
    txt: documentType.plain,
    mp3: documentType.audio,
    ogg: documentType.audio
}

function megabytesToBytes(megabytes) {
    return megabytes * 1024 * 1024
}

export const allowedDocumentTypes = [documentType.pdf]
