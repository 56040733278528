<template>
  <span class="fs-5 fw-bold">{{ $t('submission.form.comments') }}</span>
  <div class="mt-2">
    <div v-if="comments.length > sortedComments.length" class="d-flex justify-content-center mt-1 mb-1">
      <a href="#" class="fst-italic small" @click.prevent="expanded = true">
        {{ $t('comment.list.expand', {count: comments.length - sortedComments.length}) }}
      </a>
    </div>

    <SubmissionFormCommentsItem v-for="comment in sortedComments" :key="comment.id" :comment="comment"/>

    <div v-if="listLoading && sortedComments.length === 0">
      <SubmissionFormCommentsPlaceholder v-for="n in 3" :key="n"/>
    </div>

    <div v-if="!listLoading && !error && sortedComments.length === 0" class="mb-2">
      {{ $t('comment.list.empty') }}
    </div>

    <div v-if="error" class="mb-2">
      {{ error }}
    </div>
  </div>

  <form v-if="editable" @submit.prevent="addComment">
    <div class="d-flex mt-1 mb-1">
      <div class="border-end border-4 border-light pe-2 me-2">
        <ProfileImage :size="42" :user="authStore.user"/>
      </div>
      <div class="flex-fill">
        <textarea v-model="commentForm.content" class="form-control" rows="3" maxlength="500" required
                  :disabled="loading || listLoading || error"></textarea>
      </div>
    </div>
    <div class="d-flex justify-content-end align-items-center mt-2">
      <span class="text-secondary">{{ commentForm.content.length }}/500</span>
      <button type="submit" class="btn btn-primary ms-2" :disabled="loading || listLoading || error">
        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span v-if="!loading">{{ $t('comment.add') }}</span>
      </button>
    </div>
  </form>
</template>
<script setup>
import ProfileImage from "@/components/Common/ProfileImage";
import {computed, onMounted, ref} from "vue";
import {DateTime} from "luxon";
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import SubmissionFormCommentsItem from "@/components/Submission/Comments/SubmissionCommentsItem";
import SubmissionFormCommentsPlaceholder from "@/components/Submission/Comments/SubmissionCommentsPlaceholder";
import {useI18n} from "vue-i18n";
import emitter from "@/eventbus"

const emit = defineEmits(['onNewComment'])
const props = defineProps({
  submission: Object,
  initSubmissionId: String,
  editable: Boolean,
  onlyPublic: Boolean
})
defineExpose({
  loadComments
})

const i18n = useI18n()
const commentForm = ref({content: ''})
const comments = ref([])
const submissionId = ref(null)
const loading = ref(false)
const listLoading = ref(false)
const expanded = ref(false)
const error = ref(null)
const authStore = useAuthStore()

const sortedComments = computed(() => {
  let sorted = comments.value
  sorted.sort(function (a, b) {
    return DateTime.fromISO(a.created_at).toMillis() - DateTime.fromISO(b.created_at).toMillis()
  })

  if (!expanded.value && sorted.length > 5) {
    sorted = sorted.slice(sorted.length - 5)
  }

  return sorted
})

onMounted(() => {
  if (props.submission) {
    submissionId.value = props.submission.id
    comments.value = props.submission.comments
  } else if (props.initSubmissionId) {
    submissionId.value = props.initSubmissionId
    loadComments()
  }
})

emitter.on('retry', () => {
  if (error.value) loadComments()
})

function loadComments() {
  listLoading.value = true
  error.value = null
  let url = `/submission/${submissionId.value}/comments`
  if (props.onlyPublic) {
    url = `/submission/${submissionId.value}/comments/student`
  }
  axios
      .get(url)
      .then(response => comments.value = response.data)
      .catch(() => {
        error.value = i18n.t('comment.list.error')
      })
      .finally(() => listLoading.value = false)
}

function addComment() {
  loading.value = true
  axios
      .post('/comment', {'content': commentForm.value.content, 'submission_id': submissionId.value})
      .then(response => {
        commentForm.value.content = ''
        comments.value.push(response.data)
        emit('onNewComment')
        emitter.emit('toast', {text: i18n.t('comment.add_success')})
      })
      .catch(() => {
        emitter.emit('toast', {bg: 'bg-danger', text: i18n.t('comment.add_error')})
      })
      .finally(() => loading.value = false)
}
</script>
<style>

</style>