<template>
  <h4 class="mt-4">{{ $t('user.profile.caption') }}</h4>
  <div class="d-flex align-content-center justify-content-center">
    <div class="d-flex align-items-center flex-column">
      <ProfileImage :user="user" :size="128"/>
      <div class="mt-2 text-center">
        <div class="fst-italic">
          {{ $t(`common.uni_status.${user.uni_status}`) }} ({{ $t(`user.roles.${user.role}`) }})
        </div>
        <div class="fw-bold">{{ user.first_name }} {{ user.last_name }}</div>
      </div>
    </div>
  </div>
  <hr/>
  <form @submit.prevent="updateUser">
    <div class="row row-cols-1 row-cols-md-2">
      <div class="col mt-2">
        <div class="mb-2">
          <span class="fw-bold small">{{ $t('user.profile.title') }}</span><br/>
          <span>{{ user.title }}</span>
        </div>

        <div class="mb-2">
          <span class="fw-bold small">{{ $t('user.profile.first_name') }}</span><br/>
          <span>{{ user.first_name }}</span>
        </div>

        <div class="mb-2">
          <span class="fw-bold small">{{ $t('user.profile.last_name') }}</span><br/>
          <span>{{ user.last_name }}</span>
        </div>

        <div class="mb-2">
          <span class="fw-bold small">{{ $t('user.profile.gender') }}</span><br/>
          <span>{{ $t(`common.gender.${user.gender}`) }}</span>
        </div>

        <div class="mb-2">
          <span class="fw-bold small">{{ $t('user.profile.enrolment') }}</span><br/>
          <span>{{ user.description }}</span>
        </div>

        <div class="mb-2">
          <span class="fw-bold small">{{ $t('user.profile.email') }}</span><br/>
          <span>{{ user.email }}</span>
        </div>

        <div class="mb-2">
          {{ $t('user.profile.directory_info') }}
        </div>
      </div>
      <div class="col mt-2">
        <div class="mb-2">
          <label class="form-label fw-bold small" for="locale">{{ $t('user.profile.locale_label') }}</label>
          <select v-model="user.locale" class="form-select" aria-label="Language selection" id="locale">
            <option value="auto">{{ $t('user.profile.locale_auto') }}</option>
            <option value="de">Deutsch</option>
            <option value="en">English</option>
            <option value="fr">Français</option>
            <option value="es">Español</option>
            <option value="pt">Português</option>
          </select>
        </div>
      </div>
    </div>
    <div class="mt-2 d-flex justify-content-end">
      <button type="submit" class="btn btn-primary" :disabled="loading">{{ $t('common.save') }}</button>
    </div>
  </form>
</template>
<script setup>
import ProfileImage from "./Common/ProfileImage"
import {ref} from "vue";
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";

const router = useRouter()
const i18n = useI18n()
const authStore = useAuthStore()
const user = ref(authStore.user)
const loading = ref(false)

function updateUser() {
  loading.value = true
  axios
      .patch('/user/me', {locale: user.value.locale, gravatar: user.value.gravatar})
      .then(response => {
        emitter.emit('toast', {
          text: i18n.t('user.profile.save_success')
        })
        user.value = authStore.user = response.data
        router.push('/')
      })
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('user.profile.save_error')
        })
      })
      .finally(() => loading.value = false)
}
</script>
<style>

</style>