<template>
  <div class="d-flex align-items-center">
    <span class="fs-5 fw-bold">{{ $t('submission.form.assignees') }}</span>
    <div v-if="loading" class="spinner-border spinner-border-sm text-primary ms-2" role="status">
      <span class="visually-hidden">{{ $t('common.loading') }}</span>
    </div>
  </div>

  <div v-for="assignee in sortedAssignees" :key="assignee.id" class="mt-2">
    <SubmissionFormAssigneesItem :user="assignee" :disabled="loading || listLoading" @on-remove="removeAssignee"/>
  </div>

  <div v-if="assignees.length === 0 && !error &&  !listLoading" class="mt-2">
    <span class="fst-italic">{{ $t('common.unassigned') }}</span>
  </div>

  <div v-if="error" class="mt-2">
    {{ error }}
  </div>

  <div v-if="assignees.length === 0 && listLoading">
    <div v-for="n in 2" :key="n" class="mt-2">
      <SubmissionFormAssigneesPlaceholder/>
    </div>
  </div>

  <div v-if="authStore.user.uni_status === 'employee' && editable" class="d-flex mt-2 align-items-center">
    <i class="bi bi-person-plus fs-3 ms-2 me-2"></i>
    <div class="flex-grow-1">
      <UserSearch :disabled="loading || listLoading || error" :preload="[authStore.user]" :filter="assignees"
                  @on-user-selected="addAssignee"/>
    </div>
  </div>
</template>
<script setup>
import UserSearch from "@/components/Common/UserSearch/UserSearch";
import SubmissionFormAssigneesItem from "@/components/Submission/Assignees/SubmissionAssigneesItem";
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import {computed, onMounted, ref} from "vue";
import SubmissionFormAssigneesPlaceholder from "@/components/Submission/Assignees/SubmissionAssigneesPlaceholder";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const emit = defineEmits(['onAssigneeAdded', 'onAssigneeRemoved'])
const props = defineProps({
  submission: Object,
  submission_id: String,
  editable: Boolean
})

defineExpose({
  loadAssignees
})

const loading = ref(false)
const listLoading = ref(false)
const error = ref(null)

const submissionId = ref(null)
const assignees = ref([])
const authStore = useAuthStore()

onMounted(() => {
  if (props.submission != null) {
    assignees.value = props.submission.assignees
    submissionId.value = props.submission.id
  } else if (props.submission_id != null) {
    submissionId.value = props.submission_id
    loadAssignees()
  } else {
    // what do we do now?
  }
})

emitter.on('retry', () => {
  if (error.value) loadAssignees()
})

const sortedAssignees = computed(() => {
  const sortedAssigness = assignees.value

  return sortedAssigness.sort((a, b) => ('' + a.last_name).localeCompare(b.last_name))
})

function loadAssignees() {
  error.value = false
  listLoading.value = true
  axios
      .get(`/submission/${submissionId.value}/assignees`)
      .then(response => assignees.value = response.data)
      .catch(() => error.value = i18n.t('submission.assignees.error'))
      .finally(() => listLoading.value = false)
}

function addAssignee(assignee) {
  loading.value = true
  axios
      .post(`/submission/${submissionId.value}/assignee`, {assignee_id: assignee.id})
      .then(response => {
        assignees.value.push(response.data)
        emit('onAssigneeAdded', response.data)
        emitter.emit('toast', {text: i18n.t('submission.assignees.add_success')})
      })
      .catch(() => {
        emitter.emit('toast', {bg: 'bg-danger', text: i18n.t('submission.assignees.add_error')})
      })
      .finally(() => loading.value = false)
}

function removeAssignee(assignee) {
  loading.value = true
  axios
      .delete(`/submission/${submissionId.value}/assignee/${assignee.id}`)
      .then(() => {
        assignees.value = assignees.value.filter(filterAssignee => filterAssignee.id !== assignee.id)
        emit('onAssigneeRemoved')
        emitter.emit('toast', {text: i18n.t('submission.assignees.remove_success')})
      })
      .catch(() => {
        emitter.emit('toast', {bg: 'bg-danger', text: i18n.t('submission.assignees.remove_error')})
      })
      .finally(() => loading.value = false)
}

</script>
<style>

</style>