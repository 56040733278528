<template>
  <div class="dropdown">
    <input ref="input" id="userSearchDropdownInput" type="text" class="form-control dropdown-toggle"
           data-bs-toggle="dropdown" v-debounce:400ms.lock="searchUsers" :placeholder="$t('user.search.placeholder')"
           :disabled="disabled"/>
    <ul class="dropdown-menu w-100 shadow" aria-labelledby="userSearchDropdownInput">
      <li v-if="preload">
        <span class="small text-secondary ps-3 fst-italic">{{ $t('user.search.suggested') }}</span>
      </li>
      <li v-for="user in filteredAndSortedPreloadUsers" :key="user.id" class="dropdown-item"
          :style="cursorForEntry(user)" @click.prevent="selectUser(user)">
        <ProfileTag :user="user" :disabled="user.disabled"/>
      </li>
      <li v-if="preload">
        <hr class="mt-2 mb-2"/>
      </li>

      <li v-for="user in filteredAndSortedUsers" :key="user.id" class="dropdown-item" :style="cursorForEntry(user)"
          @click.prevent="selectUser(user)">
        <ProfileTag :user="user" :disabled="user.disabled"/>
      </li>

      <li v-if="loading" class="p-4 d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">{{ $t('common.loading') }}</span>
        </div>
      </li>

      <li v-if="count === 0 && !loading">
        <span class="small text-secondary ps-3 fst-italic">{{ $t('user.search.empty') }}</span>
      </li>

      <li v-if="count > 10">
        <span class="small text-secondary ps-3 fst-italic">
          {{ $t('user.search.additional_results', {'count': count - 10}) }}
        </span>
      </li>
    </ul>
  </div>
</template>
<script setup>
import ProfileTag from "@/components/Common/ProfileTag";
import axios from "axios";
import {UniStatus} from "@/enums";
import {computed, ref} from "vue";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const emit = defineEmits(['onUserSelected'])
const props = defineProps({
  uniStatus: {
    type: String,
    default: UniStatus.employee
  },
  filter: {
    type: Array,
    default: () => []
  },
  preload: {
    type: Array,
    default: () => []
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

let controller = null
const users = ref([])
const count = ref(0)
const input = ref(null)
const loading = ref(false)

const filteredAndSortedUsers = computed(() => {
  return filterAndSortUsers(users.value)
})

const filteredAndSortedPreloadUsers = computed(() => {
  return filterAndSortUsers(props.preload)
})

function searchUsers(query) {
  users.value = []
  count.value = 0
  if (query.length < 3) {
    return
  }

  controller?.abort()
  controller = new AbortController()
  loading.value = true
  axios
      .get('/user/search', {
        params: {
          query: query,
          filter_roles: ['editor', 'admin', 'founder']
        },
        signal: controller.signal
      })
      .then(function (response) {
        count.value = response.data.count
        users.value = response.data.users
      })
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}

function selectUser(user) {
  if (user.disabled) {
    return
  }
  input.value.value = ''
  emit('onUserSelected', user)
}

function cursorForEntry(user) {
  if (!user.disabled) {
    return 'cursor: pointer;'
  }

  return null
}

function filterAndSortUsers(users) {
  const modifiedUsers = users
  modifiedUsers.sort((a, b) => ('' + a.last_name).localeCompare(b.last_name))
  modifiedUsers.forEach(user => {
    user.disabled = !!props.filter.find(filterUser => filterUser.id === user.id);
  })

  return modifiedUsers
}

</script>
<style>

</style>