<template>
  <div v-if="stats" class="row row-cols-auto justify-content-evenly">
    <StatsItem :caption="$t('dashboard.stats.total')" :value="stats.total"/>
    <StatsItem :caption="$t('dashboard.stats.open')" :value="stats.open"/>
    <StatsItem :caption="$t('dashboard.stats.new_today')" :value="stats.new_today"/>
    <StatsItem :caption="$t('dashboard.stats.completed_today')" :value="stats.finished_today"/>
    <StatsItem :caption="$t('dashboard.stats.completed_overall')" :value="stats.finished_total"/>
    <StatsItem :caption="$t('dashboard.stats.documents_overall')" :value="documentsSizeValue"
               :unit="documentsSizeUnit"/>
  </div>
  <div v-else class="row row-cols-auto justify-content-md-evenly">
    <StatsPlaceholder v-for="n in 6" :key="n"/>
  </div>
</template>
<script setup>
import axios from "axios";
import {computed, onMounted, ref} from "vue";
import StatsItem from "@/components/Dashboard/Stats/StatsItem";
import prettyBytes from "pretty-bytes";
import StatsPlaceholder from "@/components/Dashboard/Stats/StatsPlaceholder";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const stats = ref(null)
const loading = ref(false)

onMounted(() => getStats())

const documentsSizeValue = computed(() => {
  return parseFloat(prettyBytes(stats.value.documents_size_total).split(' ')[0])
})

const documentsSizeUnit = computed(() => {
  return prettyBytes(stats.value.documents_size_total).split(' ')[1]
})

function getStats() {
  loading.value = true
  axios
      .get('/misc/stats')
      .then(response => stats.value = response.data)
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}
</script>
<style>

</style>