<template>
  <label class="w-100 position-relative" for="file-input" style="cursor: pointer;">
    <div :data-hovered="isHovered" @drop.prevent="onFileDrop" @dragenter.prevent="setHovered"
         @dragover.prevent="setHovered"
         @dragleave.prevent="setNotHovered">
      <slot :isHovered="isHovered"></slot>
    </div>
    <input class="opacity-0 position-absolute top-0 start-0" type="file" id="file-input" multiple
           @change="onInputChange" style="z-index: -999"/>
  </label>
</template>
<script setup>
import {onMounted, onUnmounted, ref} from "vue";

const events = ['dragenter', 'dragover', 'dragleave', 'drop']

const emit = defineEmits(['filesDropped'])

const isHovered = ref(false)

onMounted(() => {
  events.forEach((eventName) => {
    document.body.addEventListener(eventName, preventDefault)
  })
})

onUnmounted(() => {
  events.forEach((eventName) => {
    document.body.removeEventListener(eventName, preventDefault)
  })
})

function preventDefault(event) {
  event.preventDefault()
}

function setHovered() {
  isHovered.value = true
}

function setNotHovered() {
  isHovered.value = false
}

function onFileDrop(event) {
  setNotHovered()
  emit('filesDropped', [...event.dataTransfer.files])
}

function onInputChange(event) {
  emit('filesDropped', [...event.target.files])
}
</script>
<style>

</style>