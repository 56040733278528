<template>
  <div v-if="!user"
       class="rounded-circle d-flex align-items-center justify-content-center user-select-none bg-primary profile-image"
       :style="backgroundSize">
    <span class="text-white fw-bold" :style="textSize">{{ initials }}</span>
  </div>
  <div v-else
       class="rounded-circle d-flex align-items-center justify-content-center user-select-none profile-image"
       :style="backgroundSize + backgroundColor">
    <span class="text-white fw-bold" :style="textSize">{{ initials }}</span>
  </div>
</template>
<script setup>
import {computed} from "vue";

const props = defineProps({
  user: Object,
  size: {
    type: Number,
    default: 36
  },
  disabled: Boolean
})

const initials = computed(() => {
  if (props.user) {
    return props.user.first_name.charAt(0).toUpperCase() + props.user.last_name.charAt(0).toUpperCase()
  } else {
    return 'PA'
  }
})

const backgroundSize = computed(() => {
  return `height: ${props.size}px !important; width: ${props.size}px !important;`
})

const backgroundColor = computed(() => {
  if (props.disabled) {
    return `background-color: #cccccc;`
  }

  return `background-color: ${props.user.color};`
})

const textSize = computed(() => {
  if (props.size < 30) {
    return 'font-size: 0.8em;'
  } else if (props.size >= 96) {
    return 'font-size: 2.4em;'
  } else if (props.size >= 64) {
    return 'font-size: 1.6em;'
  }

  return ''
})
</script>
<style>
.profile-image {
  display: inline-block;
}
</style>