import {allowedDocumentTypes, documentSuffixToType} from "@/config";

/*
 * Generate a filler word with at least (min) and up to (max) characters
 */
export function getRandomArbitraryString(min, max) {
    const length = Math.random() * (max - min) + min

    return ''.padStart(length, '0')
}

/*
 * Generate a filler sentence with at least (min) and up to (max) words
 */
export function getRandomArbitrarySentence(min, max) {
    const length = Math.random() * (max - min) + min

    let words = []
    for (let c = 0; c < length; c++) {
        words.push(getRandomArbitraryString(2, 10))
    }

    return words.join(' ')
}

export function isDocumentAllowed(suffix) {
    if (!(suffix in documentSuffixToType)) {
        return false
    }
    const documentType = documentSuffixToType[suffix]

    return allowedDocumentTypes.includes(documentType)
}