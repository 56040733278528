<template>
  <div class="dropdown">
    <div id="userMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
      <ProfileImage :user="authStore.user"></ProfileImage>
    </div>

    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userMenuButton">
      <li>
        <span class="text-nowrap fw-bold ms-3">
          <small>{{ authStore.user.first_name }} {{ authStore.user.last_name }}</small>
        </span>
      </li>
      <li>
        <hr class="dropdown-divider">
      </li>

      <li>
        <router-link to="/profile" class="dropdown-item text-black">{{ $t('common.profile') }}</router-link>
      </li>
      <li>
        <a class="dropdown-item text-black" href="#" @click.prevent="logout">{{ $t('common.logout') }}</a>
      </li>
    </ul>
  </div>
</template>
<script setup>
import ProfileImage from "@/components/Common/ProfileImage";
import {useAuthStore} from "@/stores/auth";
import {resetAndRedirectToLogin} from "@/auth";

const authStore = useAuthStore()

async function logout() {
  await resetAndRedirectToLogin()
}
</script>
<style>

</style>