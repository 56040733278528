<template>
  <div class="modal fade modal-fullscreen-md-down" id="stateChangeModal" data-bs-backdrop="static"
       data-bs-keyboard="false" tabindex="-1" aria-labelledby="stateChangeLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="onSubmit">
          <div class="modal-header">
            <span class="fs-4 fw-bold">
                {{ $t('submission.state.dialog.caption', {state: $t(`submission.state.values.${form.state}`)}) }}
              </span>
            <button type="button" class="btn-close" data-bs-dismiss="modal"
                    :aria-label="$t('common.close')"></button>
          </div>
          <div class="modal-body">
            <div v-if="form.state !== SubmissionStates.information_needed">
              <label for="comment" class="form-label">{{ $t('submission.state.dialog.comment_help') }}</label>
              <textarea v-model="form.comment" class="form-control" rows="3" id="comment" maxlength="500"
                        :placeholder="$t('submission.state.dialog.comment_hint')"></textarea>
              <div class="d-flex justify-content-end mt-2">
                <span class="text-secondary">{{ form.comment.length }}/500</span>
              </div>
            </div>
            <div v-else>
              <label for="comment" class="form-label">
                {{ $t('submission.state.dialog.comment_help_information_needed') }}
              </label>
              <textarea v-model="form.comment" class="form-control" rows="3" id="comment" maxlength="500"
                        :placeholder="$t('submission.state.dialog.comment_hint_information_needed')"
                        required></textarea>
              <div class="d-flex justify-content-end mt-2">
                <span class="text-secondary">{{ form.comment.length }}/500</span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">{{ $t('submission.state.dialog.save') }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script setup>
import {ref, watch} from "vue";
import {SubmissionStates} from "@/enums";

const props = defineProps({
  state: String
})

const emit = defineEmits(['onFormSubmitted'])

watch(() => props.state, newValue => {
  form.value.state = newValue
  form.value.public = newValue === SubmissionStates.information_needed;
  form.value.comment = ''
})

const form = ref({
  comment: '',
  state: props.state,
  public: false
})

function onSubmit() {
  emit('onFormSubmitted', form.value)
}

</script>
<style>

</style>