<template>
  <div class="dropdown">
    <button class="btn btn-primary dropdown-toggle" type="button" id="stateFilterDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false" data-bs-auto-close="outside">
      {{ $t('common.state') }}
      <span v-if="selectedStates.length > 0" class="badge bg-danger">{{ selectedStates.length }}</span>
    </button>
    <ul class="dropdown-menu" aria-labelledby="stateFilterDropdown">
      <li v-for="state in enrichedStates" :key="state">
        <a class="dropdown-item" :class="state.active" href="#" @click.prevent="toggleStateFilter(state.name)">
          {{ $t(`submission.state.values.${state.name}`) }}
        </a>
      </li>
    </ul>
  </div>
</template>
<script setup>
import {computed, ref} from "vue";
import {SubmissionStates, UniStatus} from "@/enums";
import {useAuthStore} from "@/stores/auth";

const auth = useAuthStore()
const selectedStates = ref([])

const emit = defineEmits(['onStateFilterChanged'])

const enrichedStates = computed(() => {
  const enrichedStates = []
  for (const [state, value] of Object.entries(SubmissionStates)) {
    if (state === SubmissionStates.in_creation && auth.user.uni_status === UniStatus.employee) {
      continue
    }

    enrichedStates.push({name: value, active: selectedStates.value.includes(value) ? 'active' : ''})
  }

  return enrichedStates
})

function toggleStateFilter(state) {
  if (selectedStates.value.includes(state)) {
    selectedStates.value = selectedStates.value.filter(testState => testState !== state)
  } else {
    selectedStates.value.push(state)
  }
  emit('onStateFilterChanged', selectedStates.value)
}

</script>
<style>

</style>