<template>
  <div class="card p-0 list-group-item-action user-select-none" style="cursor: pointer"
       @click="createAndOpenSubmission">

    <div class="card-header bg-primary text-light">
      <div class="d-flex align-items-center">
        <span class="fs-5">{{ submissionType.name }}</span>
        <div v-if="loading" class="spinner-border spinner-border-sm ms-auto" role="status" aria-hidden="true"></div>
      </div>
    </div>

    <div class="card-body">
      <p class="card-text">{{ submissionType.description }}</p>
    </div>
    <div class="card-footer">
      {{ $t('wizard.type.required') }}
      <span class="badge bg-primary me-2" v-for="documentType in submissionType.document_types"
            :key="documentType.id">
        {{ documentType.name }}
      </span>
    </div>
  </div>
</template>
<script setup>
import {ref} from "vue";
import axios from "axios";
import {useRouter} from "vue-router";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const router = useRouter()
const loading = ref(false)
const props = defineProps({
  submissionType: Object
})

function createAndOpenSubmission() {
  if (loading.value) {
    return
  }

  loading.value = true
  axios
      .post('/submission', {submission_type_id: props.submissionType.id})
      .then(response => router.push(`/submission/${response.data.id}/create`))
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}
</script>
<style>
</style>