<template>
  <div v-if="!error" class="mt-4">
    <form @submit.prevent="submitSubmission">
      <SubmissionFormHead :submission="submission"/>

      <span class="fs-5 fw-bold">{{ $t('wizard.files.required_documents') }}</span>
      <p>{{ $t('wizard.files.required_documents_help') }}</p>

      <div v-if="submission">
        <RequiredDocuments :document-types="submission.submission_type.document_types"/>
      </div>

      <div v-if="submission && submission.state === SubmissionStates.information_needed" class="mt-4">
        <SubmissionFormComments :init-submission-id="`${route.params.submission_id}`" :only-public="true"/>
      </div>

      <div class="mt-4">
        <DocumentManager :init-submission-id="`${route.params.submission_id}`" :enable-upload="true"
                         :enable-deletion="true"
                         @on-task-state-changed="onDocumentManagerTaskStateChanged"/>
      </div>

      <div class="fs-5 fw-bold mt-4">{{ $t('wizard.comment.assurance_title') }}</div>
      <div>{{ $t('wizard.comment.assurance_sub') }}</div>

      <div class="form-check mt-2">
        <input v-model="form.confirmation_privacy" class="form-check-input" type="checkbox" value="" id="check1"
               required :disabled="loading">
        <label class="form-check-label" for="check1">
          {{ $t('wizard.comment.confirmation_1') }}
        </label>
      </div>
      <div class="form-check">
        <input v-model="form.confirmation_complete" class="form-check-input" type="checkbox" value="" id="check2"
               required :disabled="loading">
        <label class="form-check-label" for="check2">
          {{ $t('wizard.comment.confirmation_2') }}
        </label>
      </div>

      <div class="d-flex justify-content-end mt-4">
        <button type="button" class="btn btn-light" @click.prevent="discardSubmission"
                :disabled="loading || documentManagerWorking">
          {{ $t('common.discard') }}
        </button>
        <button type="submit" class="ms-2 btn btn-primary" :disabled="loading || documentManagerWorking">
          {{ $t('common.submit') }}
        </button>
      </div>
    </form>
  </div>
  <div v-else class="mt-4">
    {{ error }}
  </div>
</template>
<script setup>
import SubmissionFormHead from "@/components/Submission/Common/SubmissionFormHead";
import DocumentManager from "@/components/DocumentManager/DocumentManager";
import RequiredDocuments from "@/components/Common/RequiredDocuments";
import SubmissionFormComments from "@/components/Submission/Comments/SubmissionComments"
import {SubmissionStates} from "@/enums";
import {onMounted, ref} from "vue";
import axios from "axios";
import {useRoute, useRouter} from "vue-router";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const submission = ref(null)
const form = ref({
  confirmation_privacy: false,
  confirmation_complete: false
})

const loading = ref(false)
const documentManagerWorking = ref(false)
const error = ref(null)

const route = useRoute()
const router = useRouter()

onMounted(() => loadSubmission())

function loadSubmission() {
  error.value = null
  loading.value = true
  axios
      .get(`/submission/${route.params.submission_id}/student`)
      .then(response => submission.value = response.data)
      .catch(() => {
        error.value = i18n.t('wizard.error')
      })
      .finally(() => loading.value = false)
}

function submitSubmission() {
  loading.value = true
  axios
      .post(`/submission/${route.params.submission_id}/submit`, form.value)
      .then(() => {
        emitter.emit('toast', {
          text: i18n.t('wizard.comment.submit_success')
        })
        router.push(`/`)
      })
      .catch(error => {
        console.log(error)
        if (error.response && error.response.status === 424) {
          emitter.emit('toast', {
            bg: 'bg-danger',
            text: i18n.t('wizard.comment.submit_no_document')
          })
        } else {
          emitter.emit('toast', {
            bg: 'bg-danger',
            text: i18n.t('wizard.comment.submit_error')
          })
        }
      })
      .finally(() => loading.value = false)
}

function discardSubmission() {
  loading.value = true
  axios
      .delete(`/submission/${route.params.submission_id}`)
      .then(() => {
        emitter.emit('toast', {
          text: i18n.t('wizard.discard_success')
        })
        router.push('/')
      })
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('wizard.discard_error')
        })
      })
      .finally(() => loading.value = false)
}

function onDocumentManagerTaskStateChanged(isWorking) {
  documentManagerWorking.value = isWorking
}
</script>
<style>

</style>