<template>
  <div v-if="authStore.isEditor">
    <EmployeeDashboard/>
  </div>
  <div v-else>
    <StudentDashboard/>
  </div>
</template>
<script setup>
import {useAuthStore} from "@/stores/auth";
import EmployeeDashboard from "@/components/Dashboard/EmployeeDashboard";
import StudentDashboard from "@/components/Dashboard/StudentDashboard";

const authStore = useAuthStore()
</script>
<style>

</style>