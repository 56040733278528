<template>
  <div class="mt-4">
    <h4 class="mb-3" v-if="isEdit">{{ $t('submission.type.form.edit') }}</h4>
    <h4 v-else>{{ $t('submission.type.form.new') }}</h4>

    <form @submit.prevent="saveSubmissionType">
      <ul class="nav nav-tabs" id="languageTab" role="tablist">
        <li v-for="language in languages" :key="language.locale" class="nav-item" role="presentation">
          <button class="nav-link" :class="`${language.locale === 'de' ? 'active' : ''}`" :id="`${language.locale}-tab`"
                  data-bs-toggle="tab" :data-bs-target="`#${language.locale}-tab-pane`" type="button"
                  role="tab" :aria-controls="`${language.locale}-tab-pane`" aria-selected="true">
            {{ language.name }}
          </button>
        </li>
      </ul>

      <div class="tab-content" id="languageTabContent">
        <div v-for="language in languages" :key="language.locale" class="tab-pane"
             :class="`${language.locale === 'de' ? 'show active' : ''}`" :id="`${language.locale}-tab-pane`"
             role="tabpanel" :aria-labelledby="`${language.locale}-tab`" tabindex="0">

          <div class="mb-3">
            <label :for="`${language.locale}SubmissionTypeName`" class="form-label">{{ $t("common.name") }}</label>
            <input v-model="form.translations.name[language.locale]" type="text" class="form-control"
                   :id="`${language.locale}SubmissionTypeName`"
                   :aria-describedby="`${language.locale}SubmissionTypeNameHelp`"
                   maxlength="200" :disabled="loading" :required="['de', 'en'].includes(language.locale)">
            <div :id="`${language.locale}SubmissionTypeNameNameHelp`" class="form-text d-flex">
              {{ $t("submission.type.form.name_help") }}
            </div>
          </div>

          <div class="mb-3">
            <label :for="`${language.locale}SubmissionTypeDescription`" class="form-label">
              {{ $t("common.description") }}</label>
            <textarea v-model="form.translations.description[language.locale]" rows="3" class="form-control"
                      :id="`${language.locale}SubmissionTypeDescription`"
                      :aria-describedby="`${language.locale}SubmissionTypeDescriptionHelp`"
                      maxlength="500" :disabled="loading" :required="['de', 'en'].includes(language.locale)"></textarea>
            <div :id="`${language.locale}SubmissionTypeDescriptionHelp`" class="form-text d-flex">
              {{ $t("submission.type.form.description_help") }}
            </div>
          </div>

        </div>
      </div>

      <div class="mb-3">
        <label for="submissionTypeTokenPrefix" class="form-label">{{ $t('submission.type.form.token_prefix') }}</label>
        <input v-model="form.token_prefix" type="text" class="form-control" id="submissionTypeTokenPrefix"
               aria-describedby="submissionTypeTokenPrefixHelp"
               required maxlength="4" :disabled="isEdit || loading">
        <div id="submissionTypeTokenPrefixHelp" class="form-text d-flex">
          {{ $t('submission.type.form.token_prefix_help') }}
          <span class="ms-auto">{{ form.token_prefix.length }}/4</span>
        </div>
      </div>

      <div class="mb-3">
        <label for="submissionTypeDocumentTypes" class="form-label">
          {{ $t("submission.type.form.document_types") }}
        </label>
        <select v-model="form.document_type_ids" class="form-select" multiple
                aria-describedby="submissionTypeDocumentTypesHelp" required :disabled="loading || documentTypesLoading">
          <option v-for="documentType in documentTypes" :key="documentType.id" :value="documentType.id">
            {{ documentType.name }}
          </option>
        </select>
        <div id="submissionTypeDocumentTypesHelp" class="form-text">
          {{ $t("submission.type.form.document_types_help") }}
        </div>
      </div>

      <div class="mb-3">
        <label for="submissionBroadcast" class="form-label">{{ $t('submission.type.form.broadcast') }}</label>
        <input v-model="form.broadcast_address" type="email" class="form-control" id="submissionBroadcast"
               aria-describedby="submissionBroadcastHelp"
               maxlength="200" :disabled="loading">
        <div id="submissionBroadcastHelp" class="form-text d-flex">
          {{ $t('submission.type.form.broadcast_help') }}
          <span class="ms-auto">{{ form.broadcast_address ? form.broadcast_address.length : 0 }}/200</span>
        </div>
      </div>

      <div class="mb-3">
        <div class="row justify-content-md-evenly">
          <div class="col-auto">
            <label for="submissionTypeDeleteAfter" class="form-label">
              {{ $t("submission.type.form.delete_after") }}
            </label>
          </div>
          <div class="col">
            <input v-model="form.delete_after" type="range" class="form-range"
                   id="submissionTypeDeleteAfter"
                   min="1"
                   max="180"
                   :disabled="loading">
          </div>
          <div class="col-auto">
            <span>{{ $t('submission.type.days', {value: form.delete_after}) }}</span>
          </div>
        </div>
      </div>

      <div class="form-check form-switch mb-3">
        <input v-model="form.active" class="form-check-input" type="checkbox" role="switch" id="activeSwitch"
               :disabled="loading">
        <label class="form-check-label" for="activeSwitch">{{ $t('common.active') }}</label>
      </div>

      <div class="d-flex justify-content-end">
        <router-link to="/submission/types" class="btn btn-secondary me-2">{{ $t("common.cancel") }}</router-link>
        <button v-if="isEdit" @click.prevent="deleteSubmissionType" type="button" class="btn btn-danger me-2"
                :disabled="loading">
          {{ $t("common.delete") }}
        </button>
        <button type="submit" class="btn btn-primary" :disabled="loading">
          <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{ $t("common.save") }}
        </button>
      </div>

    </form>
  </div>
</template>
<script setup>
import {computed, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {Languages} from "@/constants";
import axios from "axios";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const route = useRoute()
const router = useRouter()
const form = ref({
  translations: {
    name: new Map(),
    description: new Map()
  },
  broadcast_address: '',
  token_prefix: '',
  document_type_ids: [],
  delete_after: 90,
  active: true
})

const loading = ref(false)
const documentTypesLoading = ref(false)
const documentTypes = ref([])

const isEdit = computed(() => {
  return route.params.id
})
const languages = computed(() => {
  const languages = []
  for (const [locale, language] of Languages) {
    languages.push({locale: locale, name: language})
  }

  return languages
})

onMounted(() => {
  loadDocumentTypes()
  if (isEdit.value) {
    loadSubmissionType()
  }
})

function saveSubmissionType() {
  if (loading.value) return

  if (isEdit.value) {
    editSubmissionType()
  } else {
    createSubmissionType()
  }
}

function createSubmissionType() {
  loading.value = true
  axios
      .post('/submission/type', form.value)
      .then(() => router.push('/submission/types'))
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}

function editSubmissionType() {
  loading.value = true
  axios
      .put(`/submission/type/${route.params.id}`, form.value)
      .then(() => router.push('/submission/types'))
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}

function deleteSubmissionType() {
  loading.value = true
  axios
      .delete(`/submission/type/${route.params.id}`)
      .then(() => router.push('/submission/types'))
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}

function loadDocumentTypes() {
  documentTypesLoading.value = true
  axios
      .get('/document/types')
      .then(response => documentTypes.value = response.data)
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => documentTypesLoading.value = false)
}

function loadSubmissionType() {
  loading.value = true
  axios
      .get(`/submission/type/${route.params.id}/translations`)
      .then(response => {
        form.value.translations = response.data.translations
        form.value.token_prefix = response.data.token_prefix
        form.value.delete_after = response.data.delete_after
        form.value.active = response.data.active
        form.value.broadcast_address = response.data.broadcast_address
        form.value.document_type_ids = response.data.document_types.reduce(function (acc, type) {
          acc.push(type.id)
          return acc
        }, [])
      })
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}
</script>
<style>

</style>