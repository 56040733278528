<template>
  <span v-if="isEditable" class="dropdown">
    <button type="button" class="btn dropdown-toggle" :class="stateColor" id="stateDropdownToggle"
            data-bs-toggle="dropdown" data-bs-auto-close="true"
            aria-expanded="false" :disabled="loading">
      <span v-if="loading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
      <span v-if="loading && !state">{{ $t('common.loading') }}</span>
      <span v-if="state && !loading">{{ $t(`submission.state.values.${state}`) }}</span>
    </button>
    <ul class="dropdown-menu" aria-labelledby="stateDropdownToggle">
      <li v-for="state in stateOptions" :key="state">
        <a class="dropdown-item" href="#" @click.prevent="requestStateChange(state)">
          {{ $t(`submission.state.values.${state}`) }}
        </a>
      </li>
    </ul>
  </span>
  <span v-else>
    <button type="button" class="btn" :class="stateColor" :disabled="loading">
      <span v-if="loading" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
      <span v-if="loading && !state">{{ $t('common.loading') }}</span>
      <span v-if="state && !loading">{{ $t(`submission.state.values.${state}`) }}</span>
    </button>
  </span>

  <SubmissionStateDialog :state="changeStateRequest" @on-form-submitted="changeState"/>
</template>
<script setup>
import {SubmissionStates, UniStatus} from "@/enums";
import {computed, onMounted, ref} from "vue";
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";
import SubmissionStateDialog from "@/components/Submission/Switchboard/SubmissionStateDialog";
import Modal from "bootstrap/js/dist/modal";

const i18n = useI18n()
const authStore = useAuthStore()
const emit = defineEmits(['onStateChanged'])
const props = defineProps({
  submission: Object,
  submission_id: String
})
const state = ref(SubmissionStates.in_progress)
const changeStateRequest = ref(SubmissionStates.in_progress)
const loading = ref(false)
const submissionId = ref(null)
let modal = null

onMounted(() => {
  if (props.submission) {
    submissionId.value = props.submission.id
    state.value = props.submission.state
  } else if (props.submission_id) {
    submissionId.value = props.submission_id
    loadState()
  }
})

const isEditable = computed(() => {
  return authStore.user.uni_status === UniStatus.employee && state.value !== SubmissionStates.archived
})

const stateOptions = computed(() => {
  return [
    SubmissionStates.in_progress,
    SubmissionStates.information_needed,
    SubmissionStates.done,
    SubmissionStates.rejected
  ].filter((testState) => testState !== state.value)
})

const stateColor = computed(() => {
  switch (state.value) {
    case 'in_creation':
    case 'created':
    default:
      return 'btn-info'
    case 'in_progress':
      return 'btn-progress'
    case 'information_needed':
      return 'btn-warning'
    case 'done':
      return 'btn-success'
    case 'rejected':
      return 'btn-danger'
    case 'archived':
      return 'btn-dark'
  }
})

function loadState() {
  loading.value = true
  axios
      .get(`/submission/${submissionId.value}/state`)
      .then(response => {
        state.value = SubmissionStates[response.data]
      })
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}

function requestStateChange(newState) {
  changeStateRequest.value = newState
  modal = new Modal('#stateChangeModal', {})
  modal.show()
}

function changeState(form) {
  modal.hide()
  loading.value = true
  axios
      .patch(`/submission/${submissionId.value}/state`, form)
      .then(response => {
        emitter.emit('toast', {
          text: i18n.t('submission.state.change_success')
        })
        state.value = SubmissionStates[response.data]
        emit('onStateChanged', state.value)
      })
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('submission.state.change_error')
        })
      })
      .finally(() => loading.value = false)
}


</script>
<style>

</style>