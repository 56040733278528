<template>
  <div v-if="submission">
    <div class="d-flex align-items-center mt-2">
      <span class="small text-nowrap">{{ $t('submission.form.created_at') }}</span>
      <span class="ms-1 badge bg-primary">
        {{ DateTime.fromISO(submission.created_at).toLocaleString(DateTime.DATETIME_SHORT) }}
      </span>
    </div>
    <div class="d-flex align-items-center mt-2">
      <div class="small text-nowrap">{{ $t('submission.form.updated_at') }}</div>
      <div class="ms-1 badge bg-primary ms-1">
        {{ DateTime.fromISO(submission.updated_at).toLocaleString(DateTime.DATETIME_SHORT) }}
      </div>
    </div>
    <div class="d-flex align-items-center mt-2">
      <div class="small text-nowrap">{{ deletedAtLabel }}</div>
      <div class="ms-1 badge bg-danger text-nowrap ms-1">
        {{ deletedAtDate.toLocaleString(DateTime.DATETIME_SHORT) }}
      </div>
    </div>
  </div>
  <div v-else class="placeholder-glow">
    <div class="d-flex align-items-center mt-2">
      <span class="small text-nowrap">{{ $t('submission.form.created_at') }}</span>
      <span class="fw-bold text-nowrap ms-2 placeholder rounded">
        {{ getRandomArbitraryString(16, 16) }}
      </span>
    </div>
    <div class="d-flex align-items-center mt-2">
      <div class="small text-nowrap">{{ $t('submission.form.updated_at') }}</div>
      <div class="fw-bold text-nowrap ms-2 placeholder rounded">
        {{ getRandomArbitraryString(16, 16) }}
      </div>
    </div>
    <div class="d-flex align-items-center mt-2">
      <div class="small text-nowrap">{{ $t('submission.form.delete_at') }}</div>
      <div class="fw-bold text-nowrap ms-2 placeholder rounded">
        {{ getRandomArbitraryString(16, 16) }}
      </div>
    </div>
  </div>
</template>
<script setup>
import {DateTime, Duration} from "luxon";
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import {getRandomArbitraryString} from "@/util";

const i18n = useI18n()
const props = defineProps({
  submission: Object
})

const deletedAtLabel = computed(() => {
  if (DateTime.now() > deletedAtDate.value) {
    return i18n.t('submission.form.deleted_at')
  } else {
    return i18n.t('submission.form.delete_at')
  }
})

const deletedAtDate = computed(() => {
  return DateTime.fromISO(props.submission.updated_at).plus(
      Duration.fromObject({days: parseInt(props.submission.submission_type.delete_after)})
  )
})
</script>
<style>

</style>