<template>
  <div class="mt-4">
    <div class="list-group mt-2">
      <div class="list-group-item bg-light d-flex align-items-center">
        <span class="fw-bold">{{ $t('document.type.list.caption') }}</span>
        <div v-if="loading" class="spinner-border spinner-border-sm ms-auto" role="status">
          <span class="visually-hidden">{{ $t('common.loading') }}</span>
        </div>
      </div>
      <DocumentTypeEntry v-for="documentType in documentTypes"
                         :key="documentType.id"
                         :id="documentType.id"
                         :name="documentType.name"
                         :description="documentType.description"></DocumentTypeEntry>
      <div v-if="documentTypes.length === 0" class="list-group-item d-flex align-items-center">
        <span class="pt-2 pb-2">{{ $t('document.type.list.empty') }}</span>
      </div>
    </div>
  </div>
  <div class="mt-2 d-flex justify-content-end">
    <router-link to="/document/type/" class="btn btn-primary">{{ $t('common.new') }}</router-link>
  </div>
</template>
<script setup>
import DocumentTypeEntry from "@/components/Administration/DocumentType/DocumentTypeEntry";
import {onMounted, ref} from "vue";
import axios from "axios";
import emitter from "@/eventbus";
import {useI18n} from "vue-i18n";

const i18n = useI18n()
const documentTypes = ref([])
const loading = ref(false)

onMounted(() => loadDocumentTypes())

function loadDocumentTypes() {
  loading.value = true
  axios
      .get('/document/types')
      .then(result => documentTypes.value = result.data)
      .catch(() => {
        emitter.emit('toast', {
          bg: 'bg-danger',
          text: i18n.t('common.error')
        })
      })
      .finally(() => loading.value = false)
}
</script>
<style>

</style>